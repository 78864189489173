.cccitTitleDesc{
    color: var(--ion-textcolor-accordion);
/* Medium/Small */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
.cccitTitleLink{
    color: var(--ion-textcolor-accordion);
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
}
.cccitTitleLinkCol{
padding-top: 16px;
}