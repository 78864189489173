.corefnfTitleText {
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  align-self: stretch;
  color: var(--ion-color-primary-contrast);
  font-weight: 700;
}

.corefnfWrongTitleText {
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  align-self: stretch;
  color: var(--ion-textcolor-accordion);
  font-weight: 700;
}

.corefnfRightTitleText {
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  align-self: stretch;
  color: var(--ion-color-primary-contrast);
}

.corefnfWrongTitleDescText {
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  align-self: stretch;
  color: var(--ion-textcolor-accordion);
}

.corefnfbuttonTitleText {
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-step-200);
  font-weight: 400;
}

.corefnfdiv {
  width: 100%;
  height: auto;
  display: flex;
  padding: 12px;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background-color: var(--ion-color-primary-contrast);
  text-align: center;
  cursor: pointer;
}

.corefnfseconddiv {
  width: 100%;
  height: auto;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background-color: var(--ion-color-primary-contrast);
  text-align: center;
  cursor: pointer;
}


.corefnfbarQuesContainer {
  background-color: var(--ion-textcolor-accordion);
  border-radius: 16px;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -4px 22px 0px rgba(31, 33, 51, 0.32);
}

.corefnfbarQuesWrongContainer {
  background-color: var(--ion-wrong-container);
  border-radius: 16px;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -4px 22px 0px rgba(31, 33, 51, 0.32);
}

.corefnfbarQuesRightContainer {
  background-color: var(--ion-color-secondary);
  border-radius: 16px;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -4px 22px 0px rgba(31, 33, 51, 0.32);
}

.corefnfRow {
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  justify-content: center;
}

.corefnfRowfirst {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.corefnfRowRight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}

.corefnfCol {
  align-items: flex-start;
}

.coreFnfWrongText {
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  color: var(--ion-textcolor-accordion);
  display: block;
  margin-bottom: 80px;
}

.coreFnfRightText {
  display: block;
  margin-top: 32px;
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: 700;
}