.mcqmcDescCol {
  margin-top: 16px;
}

.mcqmcDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.mcqmcQuestionTextCol {
  padding-left: 0px;
}

.mcqmcMainContainer {
  background-color: transparent !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
  padding-top: 32px;
}
