.coreformQuesText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000
}

.coreformDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2E3656
}

.coreformOptionText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #364161
}


.coreformImage {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.coreformmcqContainer {
  width: 100%;
  height: auto;
  background-color: #F5F6FA;
  border-radius: 24px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.coreformmcqQuesdiv {
  align-self: stretch;
  padding: 16px;
}

.coreformmcqDescdiv {
  align-self: stretch;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}

.coreformmcqOptionsdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: #FFF;
}

.coreformmcqOptionsTextdiv {
  padding-left: 16px;
}