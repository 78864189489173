.rpMain {
    background-color: var(--ion-color-light);
    margin-bottom: 60px;
}

.rpImageCol {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}

.rpTextCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rpText {
    padding-top: 16px;
    padding-bottom: 32px;
    color: var(--ion-default-text-color);
    text-align: center;
    /* Bold/Heading 4 */
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
}

.rpPrizeGrid {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    background: var(--ion-color-primary-contrast);
}

.rpTextSelection {
    color: var(--ion-color-tertiary-contrast);
    /* Medium/Large */
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.rpColbg {
    display: flex;
    padding: 8px;
    gap: 10px;
    border-radius: 16px;
    background: var(--ion-color-light);
    flex-direction: column;
    margin-bottom: 26px;
}
.rpDivGap{
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 24px;
    
}
.rpTextChar{
    color: var(--ion-default-text-color);
/* Medium/Small */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
.rpImg{

    display: flex;
    align-items: center;
    justify-content: center;

}
.rpCheck{
    position: absolute;
    left: 16px;
    top: 16px;
}