.cadiMain{
    /* text-align: justify; */
    /* background: var(--ion-heading-text-color); */
    border-radius: 15px;
    justify-content: left;
}
.cadiMargin{
    padding: 16px;
}
.cadiSmain{
   
    background-color:var(--ion-color-primary-contrast);
    padding:16px;
    border-radius: 16px;
}
.cadiSmainHeading{color: var(--ion-color-tertiary-contrast);
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 25.2px */}
.cadiItem{
    --background: var(--ion-color-light) !important;
    border-radius: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

p{
    font-size: 15px;
}
.cadiSText{
    color: var(--ion-default-text-color);
/* Medium/Small */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */


}
.cadiSTextDid{
    color: var(--ion-heading-text-color);
/* Bold/Lable */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
    padding-left: 16px;
}
.cadiDivGap{
    width: 100%;
    height: 16px;
}

.cadiAccordin{
    background-color:var(--ion-color-light) ;
    border: 2px solid var(--ion-color-light);
    border-radius: 15px;
    
}


.cadiUnorder{
    background-color:var(--ion-color-primary-contrast);
    padding:10px;
    font-size: 16px;
    border-radius: 20px;
}
.cadicardContent{
    background-color:var(--ion-color-light);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-top: 0px !important;
}
ion-accordion-group.Smain.md.accordion-group-expand-compact {
    background: var(--ion-color-primary-contrast);
}
