.htMainContainerDiv {
    height: 100%;
    width: 100%;
    margin-bottom: 110px;
}

.htScrollIcon {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 12px;
    z-index: 10000;
}

.htScrollIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}


.htDivText {
    margin-bottom: 16px;
}
.htImgStuff{
    width: 24px;
    height: 24px;
}
.htTextmystuff{
    color: var(--ion-color-secondary);
/* Medium/Extra Small */
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.hisDivCores {
    background-color: var(--ion-color-primary-contrast);
    margin-top: 32px;
}

.hisGridLearn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background-color: var(--ion-color-primary-contrast);
    margin-top: 32px;
    margin: 16px;
    margin-left: 0px;
}

.hisTextLearn {
    color: var(--ion-color-tertiary-contrast);
    font-size: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}
.htHeading {
    color: var(--ion-color-tertiary-contrast);
    /* Bold/Heading 3 */
    font-size: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}