.mmsMainContainer {
  /* margin: 16px; */
  margin-top: 16px;
  padding: 16px;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 16px;
}

.mmsImgCol {
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.mmsTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-heading-text-color);
}

.mmsMainMainContainer {
  padding-bottom: 24px;
}

.mmsMainDesc2Div {
  margin-top: 32px;
}

.mmsMainTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.mmsMainDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.mmsMainDesc2Text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}