.atseMainTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-heading-text-color);
  display: flex;
}

.atseImg {
  width: 70px;
}

.atseOptTextDiv {
  padding-top: 8px;
}

.atseOptText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  text-decoration-line: underline;
  color: var(--ion-default-text-color);
}

.atseOptionsRow {
  margin-top: 8px;
}

.atseOptionCol {
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.atseMainGrid {
  display: flex;
}