.scimsText2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--ion-textcolor-accordion);
}

.scimsImg1 {
    width: 57px;
}

.scimsDiv1 {
    margin-top: 8px;
}

.sccpsSpotImg {
    align-items: center;
    justify-content: center;
    display: flex;
}

.sccpsMulPositionsDiv {
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 314px;
}

.sccpPos {
    margin-bottom: 4px;
    padding-bottom: 2px;
}

.sccpMulCapturedImg {
    width: 314px !important;
}