.cccoMainContent {
    background-color: var(--ion-color-light) !important;
    padding-top: 56px;
    padding-bottom: 73px;
    margin-top: 54px !important;
}

.cccoContentHeading {
    color: var(--ion-color-tertiary-contrast);
    font-family: "Nunito";
    font-size: 20px;
    font-weight: 700;
    color: var(--ion-heading-text-color);
    line-height: 28px;
    max-lines: 0;
}
.mainContent{
    margin-top:24px;
}

.accccMainContainer{
    margin-top: 16px;
}
.accTitleText,.acceTitleText{
    font-size: 16px;
}
.accMainContainer{
    margin-left: 0;
    margin-right: 0;
}
.accMainContainer + .cccoContentHeading{
    margin-top: 32px;
    display: block;
}
.acceTitleText{
    color: var(--ion-default-text-color) !important;
}
.mainContent.custom-content{
    padding-left: 0;
    padding-right: 0;
}