

.hisTextCoreNo {
    color: var(--ion-progress-text);
    text-align: center;
    /* Bold/Lable */
    font-size: 16px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-left: 8px;
}
.hisTextCoreNo1 {
    color: var(--ion-progress-text);
    text-align: center;
    /* Bold/Lable */
    font-size: 16px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.hisTextCoreDesc {
    color: var(--ion-color-tertiary-contrast);
    font-size: 18px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.hisGridFill {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--ion-bgcolor-summary);
    background: var(--ion-color-primary-contrast);
}

.hisColSep {
    padding-left: 16px;
    padding-bottom: 6px;
}
.hisColSep1 {
    padding-left: 16px;
    padding-bottom: 6px;
}

.hisTextLorem {
    color: var(--ion-heading-text-color);
    /* Medium/Small */
    font-size: 16px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.hisButton1 {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid var(--ion-color-secondary);
    background:  var( --ion-color-secondary);
}

.hisTextLeanrnow {
    color: var(--ion-color-primary-contrast);
    text-align: center;
    /* Regular/Large */
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.hisDivBtn2{
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
border: 1px solid var(--ion-color-secondary);
background: var(--ion-color-secondary);
}