.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 9999;
}

.lsSpinnerText {
  color: var(--ion-default-text-color);
  /* Bold/Lable */
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 22.4px */
}

.lsSpinnerStyle {
  width: 75px;
  height: 75px;
}