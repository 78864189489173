.absChevronImg {
  width: 16px;
}

.absCol2 {
  padding-left: 0px !important;
}

.absCol2Checked {
    padding-left: 0px !important;
}

.absImageCheckCol {
  display: flex;
  align-items: center;
}

.absImageCheck {
  width: 22px;
  margin-right: 8px;
}

.absText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color:var(--ion-textcolor-accordion);
}

.absDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-tertiary-contrast);
}

.absDescTextCol {
    padding-top: 12px;
}

.absPointsDescTextCol {
    padding-top: 16px;
}

.absMainContainer {
    background-color: var(--ion-color-primary-contrast);
    margin-top: 16px;
    border-radius: 20px;
    padding: 16px;
}

.absViewedImgCol {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.absViewedImg {
  width: 18px;
  height: 14px;
}