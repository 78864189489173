.itf-main-container {
    display: flex;
    padding: 0px;
    padding-left: 40px;
    margin-top: 0px;
    align-items: flex-start;
    background-color: var(--ion-color-primary-contrast);
}

.itf-textfield-row {
    margin-top: 10px;
}

.itf-textfield-col {
    background-color: var(--ion-color-light);
    padding-left: 8px;
    border-bottom: 2px solid var(--ion-badge-text);
}

.itf-textfield {
    border-radius: 0px;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px !important;
    color: var(--ion-badge-text);
    --placeholder-color: var(--ion-badge-text);
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.add-itf {
    padding: 16px 0 0 0;
}

.minusCircleImg {
    width: 24px;
    height: 24px;
    padding: 1px;
}

.add-itf-text {
    color: var(--ion-color-secondary);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding: 1px 0 0 10px;
}

.close-itf {
    width: 24px;
    height: 24px;
}

.cfsCloseContainer {
    background-color: var(--ion-color-light);
    border-bottom: 2px solid var(--ion-badge-text);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
}