.ccctsGoalsRow{
    padding-top: 12px;
    padding-bottom: 5px;
    padding-left: 12px;
}
.ccctsGoalsCol{
    padding-bottom: 4px;
}
.ccctsText{
    padding-right: 4px;
}
.cccitsMain{
    border-radius: 16px;
}
.cccitsImagStrech{
    width: auto !important;
    object-fit: fill;
    height: 100%;
}
.cccitsTitleCol{
    padding-bottom: 8px;
}