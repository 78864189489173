.imageDescriptionDiv {
    background-color: var(--ion-color-primary-contrast);
    border-radius: 16px;
    padding: 8px;
    width: 100%;
}

.imageDescriptionSubDiv {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 84px;
}
.imageDescriptionColEven {
    border-radius: 12px;
    margin-bottom: 8px;
    padding-left: 0;
}
.imageDescriptionColOdd {
    border-radius: 12px;
    margin-bottom: 8px;
    padding-left: 12px;
}
.image-title-div {
    display: flex;
    color: #364161;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    justify-content: center;
    padding: 16px 0 8px 0;
}
.image-points {
    display: flex;
    color: #364161;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}