.video-player-main-container {
    width: 100% !important;
    display: flex;
    padding: 16px;
    margin-top: 16px;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--ion-color-primary-contrast);
}

.video-player {
    width: 100% !important;
    /* height: 300px !important; */
    border-radius: 17px;
    /* opacity: 0.5; */
    background: var(--ion-color-tertiary-contrast);
}

.video-player>div {
    border-radius: 16px;
}

.core-video-text-col {
    padding: 16px 0 0 0;
}

.core-video-text {
    color: var(--ion-default-text-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.video-player-main-main-div {
    display: flex;
    justify-content: center;
}