.msButtonCol1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.msButtonDes1 {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid var(--ion-color-secondary);
    background: var(--ion-color-secondary);
}

.msTextViewWon {
    color: var(--ion-color-primary-contrast);
    text-align: center;
    /* Regular/Small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}