.agsMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agaMainDivImg {
  width: 30px;
  height: 25px;
}

.agaMainDivText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: (--ion-default-text-color);
}

.agssOpenedDiv {
  padding: 16px;
  margin-top: 24px;
  border-radius: 16px;
  background-color: var(--ion-color-light);
  width: 100%;
}

.agssOpenedDivImg {
  width: 57px;
  height: 57px;
}

.arsOptionText {
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
color: var(--ion-default-text-color);


}


.agssOpenedDivDesc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.agssTextCol {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.agssOpenedDivImgCol {
  display: flex;
  align-items: center;
}

.arsDescCol {
  margin-top: 24px;
}

.arsDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-color-tertiary-contrast);
}
