.sscpsDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.sscpsDescText-bold {
  font-weight: 900;
}

.sscpsPartImg {
  margin-top: 8px;
  margin-bottom: 24px;
}

.sscpsDescText2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
  margin-top: 24px;
}

.sscpsMainContent {
  background-color: var(--ion-color-light) !important;
  padding-bottom: 127px;
}

.sscpsOptionRightBtnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 117px;
  border-radius: 12px;
  background-color: var(--ion-color-secondary);
}

.sscpsOptionRightBtnDivWithMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 117px;
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--ion-color-secondary);
}

.sscpsOptionRightBtnText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpOptionCol {
  display: flex;
  align-items: center;
}

ion-button.sscpsButton12 {
  --background: var(--ion-color-primary-contrast);
  --border-radius: 24px;
  border-radius: 24px;
  height: 30px;
  border: 1px solid var(--ion-scpartselected);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.ffsscpsButton12Col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ffsscpsButtonNext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpsSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.sscpsSpotNoDiv {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--ion-color-success);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 19 !important;
}

.sscpsSpotNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpsSpotNoContainerDiv {
  position: relative;
}

.sscpsSvg {
  width: 250px;
  height: 150px;
}

.sscpsRegionDiv {
  width: 100%;
  /* height: 200px; */
  background-color: var(--ion-color-primary-contrast);
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px;
}

.sscpsRegionText {
  margin-bottom: 16px;
  color: var(--ion-color-tertiary-contrast);
}

.selfcheckContainerRegion {
  background: var(--ion-color-light);
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
}

.selfcheckContainerRegionWhite {
  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
}

.sscpsWarningText {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 10px;
  border-radius: 16px;
  background: var(--primary-6, #e0f6fd);
}

.sscpsWarningTextFont {
  color: #000;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sscpsWarningTextFont1 {
  color: #000;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  padding-left: 5px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.overlay-footer-btns {
  background: #f5f6fa;
}

.overlay-cancel-btn {
  min-height: 49px;
}

.sscpsSkinCheckDiv {
  padding: 16px;
  position: fixed;
  bottom: 90px;
  z-index: 9999;
  background-color: var(--ion-color-secondary-contrast);
  border-radius: 14px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.triangle {
  z-index: 9999;
  transform: rotate(180deg);
  position: absolute;
  bottom: -10px;
  right: 36px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--ion-color-secondary-contrast);
  /* Change color as needed */
  display: inline-block;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

ion-button.sscpsButton123 {
  --background: var(--ion-color-primary-contrast);
  --border-radius: 24px;
  border-radius: 24px;
  height: 30px;
  border: 1px solid var(--ion-scpartselected);
  box-shadow: 0px 0px 1px 1px #cccccc !important;
}

.sscpsButton123Text {
  text-align: center;
  /* Regular/Large */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  color: var(--ion-color-step-200);
}

.sscpsOverlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 9999;
}

.reminder-title {
  color: var(--ion-modaltext);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-left: 10px;
}

.clock-img {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 0.667px;
  justify-content: center;
  align-items: center;
}

.overlay-modal-content-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-line {
  color: var(--ion-badge-text);
  height: 0;
  width: 100%;
  border-width: 2px;
}

.reminder-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remind-title {
  color: #364161;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 0px 16px 0px 16px;
}

.remind-title-bold {
  font-weight: 700;
}

.sscpsReminderTimeRow {
  background-color: var(--ion-color-primary-contrast);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.sctabcheckedImgDiv{
  /* width: 100%; */
  margin-left: 10px;
  margin-right: 10px;
}


.sctabcheckedImg{
  width: 24px;
  height: 24px;
}

.sctabcheckedImg1{
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-left: 10px;
  align-items: center;
}

.reminder-options {
  background-color: var(--ion-palette-color1);
}

.reminder-radio-img {
  margin-top: 0;
  width: 24px;
  height: 24px;
}

.reminder-options-title {
  font-size:16px;
  margin-left: 16px;
  line-height: 140%;
}

.sscpsReminderRadioDiv {
  margin-top: 0px;
  background-color: #f5f6fa;
  border-radius: 16px;
  display: flex;
  padding: 16px;
  padding-top: 0px;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
}

.sscpsReminderContent {
  --background: #f5f6fa;
}

.sscpsReminderButtonsText {
  margin-left: 8px;
  font-size: 16px;
}

.sscpsReminderSummarytext {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333;
}
 
.sscpsopenSummaryRow {
  padding-left: 16px;
  padding-right: 16px;
}
 
.sscpsopenSummaryTextlast {
  color: #364161;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.sscpsopenSummaryImage{
  width: 220px;
}
.sscpsopensummarybtndiv{
  display: flex;
height: 40px;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 24px;
border: 1px solid var(--color-light, #CCC);
background: var(--color-white, #FFF);
}
.sscpsopensummarybtnText{
  color: #333;
  text-align: center;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.sscpsopensummaryimgpad{
  padding-right: 2px;
  color: #333;
}
.sscpsopensummarytextdone{
  color: var(--color-white, #FFF);
text-align: center;
/* Regular/Large */
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 25.2px */
}
.sscpsccoImg{
  padding-right: 4px;
}
.sscpsBorderSel{
  border: 2px solid #003241;
}