.abdAudioDescBtnGrid {
  margin-top: 16px !important;
}

.accVolumeBtnDiv {
  width: 100% !important;
}

.accpersonImageDiv {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
  margin-bottom: 0px;
}

.accpersonImage {
  width: 100px;
  height: 100px;
  border-radius: 120px;
}

.accpersonColDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-bottom: 8px;
}

.accPersonText {
  color: #364161;
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
}
