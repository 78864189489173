.rbpImageBadge {
    height: 126.444px;
    padding: 12px;
}
.rbpImageBadgeLast {
    opacity: 0.5;
}

.rbpImageText {
    color: var(--ion-badge-text);
    /* Bold/Small */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* 19.6px */
}
.rbpDivProgress{
    height: 26px;
    width: fit-content;
    border-radius: 16px;
    background-color: var(--ion-color-primary-contrast);
    
    
    box-shadow: 0px -4px 15px 0px rgba(31, 33, 51, 0.11);
    display: flex;
    align-items: center;
    justify-content: center;
}
.rbpDivProgressParent{
    width: 100%;
    position: absolute;
    top: 98px;
    display: flex;
    justify-content: center;
}
.rbpDivProgressText{
    color: var(--ion-progress-text);
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 4px 12px;
}
.rbpLastBadge{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    

}