.acceMainContainer {
  background-color: var(--ion-color-light);
  border-radius: 20px;
  padding: 16px;
}

.acceTitleCol {
  padding-left: 16px;
}

.acceTitleText {
  color: var(--ion-color-tertiary-contrast);
}

.acceImgCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.acceMainContainer + .acceMainContainer {
  margin-top: 16px;
}

.absseMainContainer + .absseMainContainer {
  margin-top: 22px;
}
.acceMainContainer {
  margin-top: 16px;
}
