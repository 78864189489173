.ccsMainContainer {
  background-color: var(--ion-color-tertiary);
  margin: 0px !important;
}

.ccsImg {
  width: 17px;
}

.ccsPointsCol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 9.5px;
}

.ccsTitleCol {
  padding-bottom: 12px;
}

.ccsOptionText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.ccsOptionTextDiv {
  padding-left: 16.75px;
}

.ccsOptionRow {
  padding-left: 20px;
}

.ccsTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-heading-text-color);
}
