.mcqmcoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.mcqmcoCheckImg {
  width: 28px;
  height: 29px;
}

.mcqmcoMainContainer {
  background-color: var(--ion-color-primary-contrast);
}
