.tsohChevronImg {
  width: 10px;
}

.tsohCol2 {
  display: flex;
  align-items: center;
  padding-left: 18px;
}

.tsohCol3 {
  display: flex;
  align-items: center;
}

.tsohText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.tsohMainContainerFullWidth {
  width: 100% !important;
}

.tsohIconCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}