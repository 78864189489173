.tool-bar {
  min-height: 88px;
  --background: var(--ion-color-primary);
}
.tool-bar .toolbar-title-default {
  min-height: 88px;
  --background: var(--ion-color-primary);
}
.default .default-text{
  color:#364161;
}
.title-default{
  color:var(--ion-heading-text-color);
  margin-bottom: 22px;
}
.titleText {
  font-family: "Nunito";
  font-size: 22px;
  font-weight: 700;
  color: white;
  line-height: 30.8px;
}

.mainContent {
  /* position: absolute; */
  margin-top: 28px;
  padding-top: 4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: white;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 24px;
  padding:16px;
  padding-top: 40px;
}

ion-icon.settingsIcon {
  color: white;
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.cciExitBtn {
  margin-right: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.tool-bar {
  min-height: 88px;
  --background: var(--ion-color-primary);
}
.tool-bar .toolbar-title-default {
  min-height: 88px;
  --background: var(--ion-color-primary);
}
.tool-bar .toolbar-container {
  min-height: 88px;
}

.left-arrow {
  width: 98px;
  height: 98px;
  left: -25px;
  top: -23px;
  background: #005c6c;
  position: relative;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.left-arrow .left-arrow-img {
  position: relative;
  top: 20px;
  width: 11px;
  height: 20px;
}
.roundedconntentbox {
  border-radius: 10px;
}
.default .default-text {
  color: #364161;
}
.card-content-md {
  padding: 8px;
}
.default-border-radius {
  border-radius: 16px;
}
.buttonFooter {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0px;
  -webkit-box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
          box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
}

.cciBackButtonContainer {
  padding-bottom: 22px;
  padding-left: 40px;
  width: 98px;
  height: 98px;
  left: -25px;
  top: -23px;
  background: #005c6c;
  position: absolute;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.listimage{
  background-color: #005C6C;
  border-radius: 50%;
  width: 20px!important;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

margin-right: 5px;

/* Medium/Lable */

font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 140%;
/* or 15px */

display: flex;
align-items: center;
text-align: center;

/* Blue/8 */

color: #FFFFFF;
}

.list-row{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-right: 10px;
}

.list-row:last-child{
  margin-bottom: 0;
}
.loginBtnEnabled button{
  background: var(--ion-color-secondary);
}
