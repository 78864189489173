.fsrStarImg {
  width: 45px;
  height: 45px;
}

.fsrStarImgCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fsrStarImgColFlexStart {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.fsrVeryImpTextCol {
  display: flex;
  justify-content: center;
}

.fsrVeryImpText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-count-color);
}

.fsrVeryImpTextGrid {
  position: absolute;
  width: 100%;
  bottom: -30px;
}

.fsrCornerText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--ion-color-tertiary-contrast);
}
