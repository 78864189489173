ion-img.tabsImg {
  width: 18px;
  height: 18px;
}

.tabButtonUnselected {
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-heading-text-color);
  line-height: 19.6px;
}

.tabButtonSelected {
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 700;
  color: var(--ion-color-secondary);
  line-height: 19.6px;
}

ion-tab-bar {
  box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
}
