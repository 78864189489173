.msTextHeading {
    color: var(--ion-color-tertiary-contrast);
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;


}

.msImageCol {
    padding-right: 16px;
}

.msTextCol {
    display: flex;
    align-items: center;
    justify-content: center;
}

.msTextGoals {
    color: var(--ion-color-tertiary-contrast);
    text-align: center;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msImageRowGoals {
    display: flex;
    align-items: center;
    justify-content: center;
}

.msImageColGoals {
    /* padding-bottom: 16px; */
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.msImageGoals {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.msGridFill {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--ion-bgcolor-summary);
    background: var(--ion-color-secondary-contrast);
}

.msTextColGoals {
    display: flex;
    align-items: center;
    justify-content: center;
}

.msTextBadges {
    color: var(--ion-color-tertiary-contrast);
    text-align: center;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.msTextViewAll {
    color: var(--ion-color-secondary-contrast);
    text-align: center;
    /* Regular/Small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.msTextBadgesEarned {
    color: var(--ion-textcolor-accordion);
    /* Bold/Heading 4 */
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.msTextEarnedBadges {
    color: var(--ion-progress-text);
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.msTextTotalBadges {
    color: var(--ion-badge-text);
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.msImagebeCol {
    gap: 16px;
    padding-right: 16px;
}

.msButtonDes {
    display: flex;

    /* height: 29px; */
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid var(--ion-color-step-800);
    background: var(--ion-color-primary-contrast);
}

.msTextView {
    color: var(--ion-color-step-200);
    text-align: center;
    /* Regular/Small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.msButtonCol{
    display: flex;
    align-items: center;
    justify-content: center;
}