.rbpmainContent {
  /* position: absolute; */
  margin-top: 25px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: var(--ion-color-primary-contrast);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}

.rbpColheaderLeft {
  display: flex;
  padding: 8px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* flex: 1 0 0; */
  border-radius: 16px 0px 0px 0px;
  background: var(--ion-color-primary-contrast);
}

.rbpColheaderRight {
  display: flex;
  padding: 8px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 16px 0px 0px;
  background: var(--ion-color-primary-contrast);
}

.rbpColheaderPrize {
  box-shadow: 0px -3px 0px 0px var(--ion-color-secondary) inset;
}

.rbpColdivheader {
  display: flex;

  justify-content: center;
  align-items: center;
  /* gap: 4px; */
  background: var(--ion-color-primary-contrast);
  box-shadow: 0px -3px 0px 0px var(--ion-color-secondary) inset;
  padding-top: 0px !important;
}

.rbpTextRewardsDeselected {
  color: var(--ion-progress-text);
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.rbpTextRewardsSelected {
  color: var(--ion-color-secondary);
  /* Bold/Lable */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 22.4px */
}

.rbphowToEarn {
  display: flex;
  padding: 8px;

  align-items: center;
  gap: 8px;
  /* flex: 1 0 0; */
  /* align-self: stretch; */
  border-radius: 16px;
  background: var(--ion-color-tertiary);
}

.rbpTexthowtoEarn {
  color: var(--ion-color-secondary);
  /* Medium/Extra Small */
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.rbpColQues {
  padding-right: 8px;
}

.rbpColLeft {
  padding-left: 16px;
}

.rbpTextNumber {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  background: var(--ion-textnumbrer);
  color: var(--ion-color-primary-contrast);
  text-align: center;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.rbpBadgePModalTitleRow {
  margin-top: -10px;
  margin-bottom: 7px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbpBadgePModalTitleTextCol {
  padding-left: 8px;
}

.rbpBadgePModalTitleDivider {
  width: 100%;
  height: 1px;
  background-color: var(--ion-badge-text);
}

.rbpBadgePModalBadgeImg {
  width: 110px;
  /* height: 185px; */
}

.rbpBadgePModalBadgeImgCol {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.rbpBadgePModalBadgeProgressCol {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 24px;
}

.rbpBadgePModalBadgeDesc2Col {
  padding: 0px 32px;
  padding-top: 24px;
}

.rbpPModalText {
  color: var(--ion-modaltext);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 137.5% */
}

.rbpBadgePModalBadgeDesc2Text {
  color: var(--ion-default-text-color);
  text-align: center;
  /* Bold/Heading 4 */
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.rbpBadgePModalBadgeGoToBtnDiv {
  background-color: var(--ion-color-secondary);
  padding: 4px 16px;
  border-radius: 16px;
}

.rbpBtnText {
  color: var(--ion-color-primary-contrast);
  text-align: center;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.rbpBadgePModalHintDiv {
  width: 100%;
  height: 300px;
  background: var(--ion-color-light);
  border-radius: 16px;
  margin: 0px 16px;
  padding: 16px;
}

.rbpQuestionImg {
  width: 32px;
}

.rbpQuestionImgQstnCol {
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.rbpAnswerCol {
  padding-top: 16px;
}

.rbpCompleteBadgeText {
  color: var(--ion-default-text-color);
  text-align: center;
  /* Bold/Heading 4 */
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
}

  .rbpCompleteBadgeTextLorum {
    color: var(--ion-default-text-color);
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .modalStyle{
    border-radius: 16px;
  }
  .rbpCompleteBadgeTextQues{
    color: var(--ion-default-text-color);
    text-align: center;
    /* Bold/Heading 4 */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */

  }


.rbpBadgePModalBadgeImgGrid {
  padding: 16px;
}

.rbpCompletetimeGrid {
  /* display: flex; */
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: var(--ion-color-tertiary);
  margin-top: 8px;
  margin-right: 16px;
  margin-left: 16px;
}

.rbpRDT {
  color: var(--ion-default-text-color);
  /* Bold/Lable */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 22.4px */

}

.rbpRD {
  color: var(--ion-default-text-color);
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */

}

.rbpRowDT {
  padding-bottom: 8px;
  padding-right: 16px;
}