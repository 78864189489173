.ccaqMainContainer {
    background-color: var(--ion-color-primary-contrast);
    /* margin: 16px; */
}

.ccaqTitleText {
    font-family: "Nunito";
    font-size: 18px;
    font-weight: 700;
    color: var(--ion-heading-text-color);
    line-height: 25.2px;
    max-lines: 0;
}