.sceImg {
    width: 280px;
    height: 194px;
  }
  
  .sceImgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pswMainTextDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  
  .pswMainText {
        color: var(--ion-textcolor-accordion);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
  
  .pswMainText2Div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }
  
  .pswMainText2 {
    color: var(--ion-color-step-200);
text-align: center;
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 25.2px */
  }
  
  .pswMainText3Div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 16px;
  }
  
  .pswMainText3 {
    color: var(--ion-color-tertiary-contrast);
    text-align: center;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .pswgap{
    padding-top:16px ;
  }
  
  .sceContinueImg {
      margin-right: 9px;
      width: 22px;
  }