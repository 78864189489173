.mainContent {
  /* position: absolute; */
  margin-top: 25px;
  /* padding-top: 4px; */
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: var(--ion-color-primary-contrast);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 24px;
}


 #background-content{
  left: 0px;
  right: 0px;
  top: calc(var(--offset-top) * -1);
  bottom: calc(var(--offset-bottom) * -1);
  position: absolute;
  background: var(--background);
}
.default-card{
  background: var(--ion-color-primary-contrast);
}
.mainContent.cccoMainContent,.mainContent.cceMainContent{
  margin-top:54px;

}
