.titleZ{
z-index: 99999 !important
}
.cc6TextColor1{
    color: red !important;
}
.cc6TextColor2{
    color: orange !important;
}
.cc6TextColor3{
    color: brown !important;
}
.cc6TextColor4{
    color: green !important;
}
.cc6TextColor5{
    color: blue !important;
}
.cc6TextColor6{
    color: purple !important;
}
.cc6TextColor7{
    color: magenta !important;
}