.scpProgressMainContainer {
  padding: 16px;
  background-color: var(--ion-textcolor-accordion);
  width: 100%;
  height: fit-content;
  border-radius: 16px;
}

.scpProgressTitle {
  font-size: 18px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.scpProgressTitleCol {
  padding-left: 8px;
  margin-bottom: 16px;
}

.scpImageCol {
  padding-right: 8px;
}
.scpTextCol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scpRow {
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
}
.scpGraphBG {
  width: 100%;
  /* border-top: 1px solid var(--ion-color-primary-contrast); */
}
.scpGraphAxisLineDiv {
  margin-top: 43px;
  width: 100%;
  height: 1px;
  background-color: var(--ion-color-primary-contrast);
}

.scpGraphBGXLabelImDiv {
  transform: rotate(270deg);
  position: absolute;
  left: -20px;
  top: 71px;
}

.scpGraphBGXLabelDiv {
  width: 20px;
}

.scpGraphBGXLabelCol {
  display: flex;
  align-items: center;
}

.scpBarsBG {
  position: absolute;
  top: 3px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  justify-content: space-between;
  transform: rotate(180deg);
}

.scpBar {
  margin-top: 2px;
  width: 32px;
  height: 50%;
  background-color: var(--ion-skincheckprogressbar);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation: height-animation 3s;
}
.scpBarInComp{
  background-color: var(--ion-bgcolor-summary);
  margin-top: 2px;
  width: 32px;
  height: 50%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@keyframes height-animation {
  from   {height: 0px;}
  to {height: 100%;}
}

.scpGraphAxisLineXNoDiv {
  position: absolute;
  top: -12px;
  left: -21px;
}

.scpGraphAxisLineXNoDivBottom {
  position: absolute;
  bottom: -9px;
  left: -21px;
}

.scgMonthsDiv {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  width: "100%";
  height: "20px";
}

.scpGraphBGXLabelImDivText {
  color: var(--ion-color-light);
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.scpInfoImg {
  width: 21px;
}

.scpGraphAxisLineXNoText {
  color: var(--ion-color-light);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
}

.scpGraphBGUpperLineDiv {
  height: 1px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 3px;
}