.hiMainContainer {
  /* margin: 16px; */
  margin-top: 0px;
  background-color: var(--ion-main-container-homeintro);
  border-radius: 16px;
  padding: 16px;
}

.hiImg {
  width: 92px;
  height: 75px;
}

.hiTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  color: var(--ion-color-tertiary-contrast);
}

.hiDesc {
  margin-top: 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--ion-color-tertiary-contrast);
}

.hiQstn {
  margin-top: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25.2px;
  color: var(--ion-color-tertiary-contrast);
}

.hiBtn {
  height: 29px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  background-color: var(--ion-color-secondary);
  padding: 0px 16px 0px 16px;
  border-radius: 24px;
}

.hiBtnText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    color: var(--ion-color-primary-contrast);
}

.hiCol1 {
  display: flex;
  align-items: center;
}

.hiCol2 {
  padding-left: 16px;
}
