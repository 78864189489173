.spGridTileImgOp {
    opacity: 0.5;
  }
 
  .spGridTileColDiv {
    margin-bottom: 18px;
    height: 111px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -9999;
  }

  .gpGridTileBtn {
    background-color: var(--ion-color-secondary);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: absolute;
    padding: 4px 16px;
  
    color: var(--ion-color-primary-contrast);
    text-align: center;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  
  .spGridTileColDivOdd {
    background-color: rgba(153, 193, 248, 0.5);
  }
  
  .spGridTileColDivEven {
    background-color: rgba(115, 173, 187, 0.5);
  }
  
  .spGridTileImg {
    opacity: 0.5;
  }
  
  .spGridTileBtn {
    background-color: var(--ion-color-secondary);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: absolute;
    padding: 4px 16px;
  
    color: var(--ion-color-primary-contrast);
    text-align: center;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  
  .spGridTileText {
    color: var(--ion-badge-text);
    text-align: center;
    /* Bold/Small */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  
  .spGridTileTextDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  
  .spGridTileProgressDiv {
    height: 26px;
    /* width: 30px; */
    border-radius: 16px;
    background-color: var(--ion-color-primary-contrast);
    position: absolute;
    top: 98px;
    box-shadow: 0px -4px 15px 0px rgba(31, 33, 51, 0.11);
  }
  
  .spGridTileProgressDivText {
    color: var(--ion-progress-text);
    text-align: center;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding: 4px 12px;
  }
  