.progressbar-outer {
  width: 100px;
  height: 50px;
  background: var(--ion-color-secondary);
  /* border-radius: 50%; */
  border-radius: 100px 100px 0 0;
  /* padding: 20px; */
  margin: auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 89px;
}

.progressbar {
  width: 100px;
  height: 62px;
  margin: auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 113px;
}

.progressbarTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-primary-contrast);
  width: 52px;
  height: 52px;
  border-radius: 26px;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 113px;
}

.progressbarTextDivBig {
  width: 76px;
  height: 76px;
  border-radius: 38px;
}

.progressbarText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: var(--ion-color-secondary);
}

.progressbarText1 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  text-align: center;
  color: var(--ion-color-secondary);
}

