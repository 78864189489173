ion-grid.titleSubtitleGrid {
  margin-top: 50px;
}

ion-text.title {
  color: var(--ion-color-text-gray);
  font-family: "Quicksand-Bold";
  font-size: 34px;
  letter-spacing: 0;
  line-height: 43px;
}

.loginSubtitleTextCol {
  margin-top: 0px;
}

.textInputGrid {
  margin-top: 20px;
}

.loginBtn {
  width: 100%;
  --border-radius: 24px;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-text-color);
  opacity: 0.5;
  line-height: 25.2px;
  text-transform: capitalize;
  min-height: 49px;
}

.loginBtnEnabled {
  width: 100%;
  --border-radius: 24px;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-text-color);
  line-height: 25.2px;
  text-transform: capitalize;
  min-height: 49px;
}

.loginTextFieldCol {
  margin-top: 15px;
}

.loginForgotPasswordTextCol {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.loginLoginBtnCol {
  margin-top: 16px;
}

.loginLogoImg {
  width: 160px;
  height: 133px;
}

.loginLogoImgCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loginTitleTextCol {
  margin-top: 40px;
}

.loginTitleText {
  font-family: "Nunito";
  font-size: 28px;
  line-height: 44px;
  font-weight: 700;
  color: var(--ion-heading-text-color);
  line-height: 32px;
}

.loginSubtitleText {
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-heading-text-color);
  line-height: 32px;
}

.loginRegisterTextCol {
  margin-top: 24px;
}

.loginRegisterText {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 16px;
  color: var(--ion-link-description-color);
  line-height: 22.4px;
}

.loginRegisterTextLink {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 700;
  color: var(--ion-link-text-color);
  line-height: 22.4px;
  margin-left: 4px;
}

.loginForgotPasswordText {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 700;
  color: var(--ion-link-text-color);
  line-height: 22.4px;
  cursor: pointer;
}

.loginLoginBtn {
  width: 100% !important;
}
.lBack {
  padding-bottom: 22px;
  padding-left: 40px;
  width: 98px;
  height: 98px;
  left: -25px;
  top: -23px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.testingBtn {
  background-color: rgba(255, 0, 0, 0.03);
  width: 100%;
  --border-radius: 4px;
  font-family: "Quicksand-Regular";
  font-size: 14px;
  margin-top: 72px;
}
.testingtext {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 16px;
  color: var(--ion-color-primary-contrast);
  line-height: 22.4px;
}
.error-message {
  color: red;
  font-size: 18px;
  font-family: "Nunito";
  font-weight: 800;
}
.forgot-password-col {
  margin-top: -16px;
}
