:host {
  --background-checked: var(--ion-color-secondary);
}

ion-toggle {
  padding: 0 12px;
  --handle-width: 27px;
  --handle-height: 27px;
  --handle-max-height: auto;
  --handle-spacing: 2px;
  contain: none;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  height: 31px;
  width: 52px;
  background: var(--ion-color-secondary);
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

ion-toggle::part(handle) {
  background: var(--ion-color-primary-contrast);
  border-radius: 73px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

ion-toggle.toggle-checked::part(handle) {
  background: var(--ion-color-primary-contrast);
}

.link-label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--ion-heading-text-color);
}

.subAccMainContainer.skinCheckContainer {
  padding: 12.5px 16px;
}
