.sccTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: var(--ion-heading-text-color);
}

.sccBackImg {
  width: 22px;
}

.sccBackBtnContainer {
  background-color: var(--ion-color-primary-contrast) !important;
}

.sccPhotoImgDiv {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sccPhotoImg {
  width: 195px;
}

.sccCameraBtnImgDiv {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;

  width: 100%;
  /* top: 300px; */
}

.sccCameraBtnImg {
  width: 100px;
}

ion-modal#skin-modal {
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#skin-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}

.header {
  border-bottom: 1px solid var(--ion-badge-text);
  margin-bottom: 32px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.text-black {
  color: var(--ion-color-tertiary-contrast);
}

.skin-icon {
  margin-right: 12px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pull-right {
  float: right;
  clear: both;
}

.clearfix {
  clear: both;
}

.skin-ioncontent {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  text-align: center;
}

.info-card {
  padding: 16px;
  gap: 16px;
  background: var(--ion-color-primary-contrast);
  border: 1px solid var(--ion-bgcolor-summary);
  border-radius: 16px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.info-card+.info-card {
  margin-top: 40px;
}

.sccpOverlay {
  width: 100%;
  height: 70px;
  background-color: var(--ion-color-tertiary-contrast);
  opacity: 0.5;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
}

.sccpNoteText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ff0000;
}

.sccpNoteGrid {
  margin: 16px;
}