.scsrMainContent {
  background-color: var(--ion-color-light) !important;
}

.scsrHeader {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.scsrHeader1 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.scsrHeader2 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.skinspotcontent {
  min-height: 100vh;
  background: var(--ion-color-light);
  padding-bottom: 80px;
}

.primary-card {
  padding: 16px;
  gap: 32px;
  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  margin: 24px 0;
  margin-top: 0px;
}

.primary-card-row {
  width: 100%;
}

.border-img {
  border: 1px solid var(--ion-color-tertiary-contrast);
  border-radius: 16px;
}

.default-skinspot {
  /* max-width: 142px; */
  height: 117px;
}
.spotlogged-archive-div{
  display: flex;
  width: 100%;
  justify-content:flex-end;
  margin: 10px;
  margin-left: 0px;
}

.spotlogged-row{
 
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spotlogged-title{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  align-content: center;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
  text-align: center;
  color: var(--ion-textcolor-accordion);

}

.spotlogged-date-zone {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--ion-progress-text);
  margin-left: 8px;
}

.spotlogged-archive-text {
  font-family: "Nunito";
  font-style:normal;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: var(--ion-progress-text);
  margin-left: 8px;
}

.spotlogged-date {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--ion-progress-text);
  position: absolute;
  top: 0px;
}

.spotlogged-zone {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-progress-text);
  position: absolute;
  top: 0px;
}

.date-list {
  margin-bottom: 12px;
}

.image-block {
  margin-bottom: 16px;
}

.selected-option {
  padding: 4px 8px;
  width: 28px;
  height: 30px;
  background: var(--ion-textnumbrer);
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--ion-color-light);
}

.arrow-next {
  width: 12px;
}

/* .image-block ion-col {
  padding: 0 16px;
} */
.scsrLogSpotBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border: 1px solid var(--ion-color-step-800);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: var(--ion-color-primary-contrast);
  --background: transparent;
  margin-top: 16px;
  /* margin-bottom: 24px; */
  --background-activated: transparent;
  --box-shadow: none;

  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.scsrNewSpotText {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.scsrEmptyImageDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid red;

  display: flex;
  justify-content: center;
  align-items: center;
}

.scsrCheckImage {
  width: 20px;
  height: 20px;
}

.scsrEmptyImgText {
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: red;
}

.selected-option-more {
  background: var(--ion-color-danger);
}

.sscRevisitSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid #187685;
}

.sscRevisitSvg {
  width: 100% !important;
}

.sscpsSpotRevisitNoContainerDiv {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.sscSvgContainer {
  width: 146px;
}

.sscpsSpotRevisitNoDiv {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background-color: var(--ion-color-success);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.sscpsSpotRevisitNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.ssrModalText {
  color: #2E3656;

  /* Bold/Heading 1 */
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 33.6px */
}

.spot-removed-message {
  padding: 8px 12px;
  gap: 8px;
  flex: 1 0 0;
}

.spotremoved-close-icon {
  padding-left: 12px;
}

.spot-removed-col {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #00B578;
  margin-bottom: 24px
}