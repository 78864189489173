.abssePointsText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--ion-textcolor-accordion);
}

.abssePointsCircle {
  margin-top: 8px;
  width: 6px;
  height: 6px;
  background-color:var(--ion-textcolor-accordion);
  border-radius: 3px;
}

.abssePointCol {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.absseOptTextDiv {
  padding-left: 8px;
}

.abssePointsDescCol {
  margin-top: 8px;
  margin-bottom: 8px;
}

.absseMainContainer {
  background-color: var(--ion-color-tertiary);
  position: relative;
  margin-top: 24px;
}

.abssTitleDivDiv {
  width: 100%;
  height: 34px;
  background-color: transparent;
  position: absolute;
  top: -17px;
  left: 0px;
  display: flex;
  justify-content: center;
}

.abssTitleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  background-color: var(--ion-color-secondary);
  padding: 10px;
  border-radius: 26px;
}

.abssTitleDiv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  background-color: var(--ion-titlediv-accordion);
  padding: 10px;
  border-radius: 26px;
}

.absseNoneOfTheseImg {
  margin-top: 10px;
  width: 151px;
  height: 108px;
}

.absseNoneOfTheseImgCol {
  display: flex;
  justify-content: center;
}

.absseNoneOfTheseText {
  margin-top: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-textcolor-accordion);
}
