.scsrDiv1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 32px;
  width: 100%;

  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  margin-bottom: 16px;
}

.scsrDiv2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 100%;

  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
}

.scsrGrid1 {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.scsrNoteGrid {
  padding: 5px;
  width: 100%;
  margin-top: 5px;
}

.scsrText1 {
  padding: 0px;
  width: 181px;
  height: 31px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  color: var(--ion-textcolor-accordion);
}

.scsrDivider {
  padding-top: 8px;
  padding-bottom: 8px;
}

.scsrGrid {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.scsrText2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-tertiary-contrast);
  width: 100%;
}

.scsrRow {
  padding-top: 16px;
  padding-bottom: 16px;
}

.scsrCol2 {
  position: relative;
  display: flex;
  height: 131px;
  width: 100%;
  left: 0px;
  top: 0px;
  /* border-radius: 16px; */
  justify-content: center;
  align-items: center;
}

.scsrText4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-tertiary-contrast);
  width: 100%;
}

.scsrButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 4px 12px; */
  gap: 4px;
  width: 48px;
  height: 26px;
  border-radius: 24px;
  border: 1px solid var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
}

.scsrButtonDiv1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 4px;
  border-radius: 8px;
  background-color: var(--ion-color-success);
}

.scsrButtonDiv2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
}

.scsrButtonDiv3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  gap: 10px;
  border-radius: 8px;
  background-color: var(--ion-color-danger);
}

.scsrText3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: var(--ion-color-primary-contrast);
}

.scsrTextAddPhoto {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.scsrPlusImg {
  padding-left: 12px;
  padding-right: 12px;
}

.scsrText7 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-spotreview-text);
}

.scsrCol1 {
  /* padding: 4px; */
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.scsrText5 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: var(--ion-color-tertiary-contrast);
}

.scsrNoteText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
color: #ff0000;
}

.scsrText5-bold {
  font-weight: 900;
}

.scsdNextBtn {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.scsrRow4 {
  flex-direction: row;
  width: 100%;
  height: 175px;
  border: 2px dashed var(--ion-color-secondary);
  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.scsrCol4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scsrImg2 {
  display: flex;
  width: 100%;
  height: 99px;

  justify-content: center;
  align-self: center;
}

.scsrMar {
  margin-top: 55px;
}

.scsrRow1 {
  padding: 4px;
}

.scsrImgNoOfSpotsTextDiv {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--ion-color-success);
  top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scsrImgNoOfSpotsText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.scsrPreviewImg {
  width: 314px !important;
  /* margin-left: 32px; */
}

.scsrText7-bold {
  font-weight: 700;
}

.scsrTextNoPhoto {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  text-align: center;
  color: var(--ion-color-tertiary-contrast);
}

.alert-title,
.alert-message {
  color: var(--ion-color-tertiary-contrast) !important;
}

.sscpsSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sscpsSpotNoContainerDiv {
  position: relative;
}

.sscpsSpotNoDiv {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--ion-color-success);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9 !important;
}

.sscpsSpotNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpsSvg {
  width: 250px;
  height: 150px;
}

.sscpsImgSpot {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.sccpsPositionsDiv {
  width: 314px;
  position: relative;
}
