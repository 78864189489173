.cccMar{
    margin-top: 34px;
}
.cccMarRow{
    /* margin-bottom: 30px; */
}
.cccColSet{
    display: flex;
    justify-content: center;
}
.cccHeadText{
    color: #000;
/* Bold/Heading 4 */
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 25.2px */
}
.cccGridMar{
    padding: 16px;
}
.ccctext{
    font-size: 20px !important; 
}