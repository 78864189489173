.tool-bar {
  min-height: 88px;
  --background: var(--ion-color-primary);
}

.tool-bar .toolbar-title-default {
  min-height: 88px;
  --background: var(--ion-color-primary);
}

.default .default-text {
  color: var(--ion-default-text-color);
}

.title-default {
  color: var(--ion-heading-text-color);
  margin-bottom: 22px;
}

.titleText {
  font-family: 'Nunito';
  font-size: 22px;
  font-weight: 700;
  color: var(--ion-color-primary-contrast);
  line-height: 30.8px;
  z-index: 9999;
}

/* @media screen and (max-width: 375px) {
  .titleText {
    font-size: 100%;
    z-index: 9999;
  }
} */
.mainContentGray {
  /* position: absolute; */
  margin-top: 28px;
  padding-top: 4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: var(--ion-color-light) !important;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 90px !important;
  padding: 16px;
  padding-top: 40px;
}

.mainContent {
  /* position: absolute; */
  margin-top: 24px;
  padding-top: 4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: var(--ion-color-primary-contrast) !important;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 24px;
  padding: 16px;
  padding-top: 40px;
}

.cccoMainContent {
  /* padding-top: 56px; */
  padding-bottom: 73px;
  margin-top: 54px !important;
}

ion-icon.settingsIcon {
  color: var(--ion-color-primary-contrast);
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.cciExitBtn {
  margin-right: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.tool-bar {
  min-height: 88px;
  --background: var(--ion-color-primary);
}

.tool-bar .toolbar-title-default {
  min-height: 88px;
  --background: var(--ion-color-primary);
}

.tool-bar .toolbar-container {
  min-height: 88px;
}

.left-arrow {
  width: 98px;
  height: 98px;
  left: -25px;
  top: -23px;
  background: var(--ion-color-secondary);
  position: relative;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.left-arrow .left-arrow-img {
  position: relative;
  top: 20px;
  width: 11px;
  height: 20px;
}

.roundedconntentbox {
  border-radius: 10px;
}

.default .default-text {
  color: var(--ion-default-text-color);
}

.card-content-md {
  padding: 8px;
}

.default-border-radius {
  border-radius: 16px;
}

.buttonFooter {
  background-color: var(--ion-color-primary-contrast);
  width: 100%;
  position: fixed;
  bottom: 0px;
  -webkit-box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
  box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
}

.cciBackButtonContainer {
  z-index: 9999;
  padding-bottom: 22px;
  padding-left: 40px;
  width: 98px;
  height: 98px;
  left: -25px;
  top: -23px;
  background: var(--ion-color-secondary);
  position: absolute;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.listimage {
  background-color: var(--ion-color-secondary);
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  margin-right: 5px;

  /* Medium/Lable */

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  /* or 15px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Blue/8 */

  color: var(--ion-color-primary-contrast);
}

.list-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  margin-bottom: 10px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 10px;
}

.list-row:last-child {
  margin-bottom: 0;
}

.loginBtnEnabled button {
  background: var(--ion-color-secondary);
}

.ccaDescText {
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 500;
  color: var(--ion-textcolor-accordion);
  line-height: 25.2px;
  max-lines: 0;
}

.ccaDescText ul {
  color: var(---ion-textcolor-accordion);
}

.cccLessonTitleCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* ion-content.cccIonContentLesson {
  --padding-top: 10px;
} */

.cccLessonTitle {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.cccHeadingNormalText {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.cccSetGoalBtnContainerCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cccImgTextColTextCol {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cccImgTextColText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-tertiary-contrast);
}

.cccSideheadingSmallText {
  /* position: absolute; */
  /* margin-top: 38px; */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.cccSideheadingSmallMixedText {
  /* position: absolute; */
  /* margin-top: 38px; */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.cccImgTextColGrid {
  padding-bottom: 20px;
}

.cccFSRContainer {
  margin-top: 15px;
  margin-bottom: 32px;
}

.cccImgTextColBoldText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: var(--ion-default-text-color);
}

.cccImgTextColBoldTextCol {
  padding-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cccImgTextColBoldGrid {
  padding-bottom: 0px;
}

.cccHeadingCenter18700TextCol {
  padding-top: 24px;
}

.cccHeadingCenter18700Text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-default-text-color);
}

.cccOverviewGoalDescText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--ion-default-text-color);
}

.cccCccitTitleTextCol {
  padding-top: 0px !important;
}

.cccTitleImgSmall {
  width: 130px;
}

.cccTitleImgSmall240 {
  width: 240px;
}

.cccTitleImgSmall150 {
  width: 150px;
}

.cccTitleImgCol {
  padding-top: 0px !important;
}

.cceTitleTextCol {
  padding-top: 18px !important;
}

.cccSideHeading72TextDiv {
  margin-top: 18px;
}

.cccSideSubHeading16TextDiv {
  margin-top: 16px;
}

.cccImgtextColImg {
  width: 110px;
}

.cccImgTextColGrid8 {
  padding-bottom: 8px;
}

.cccLoginBtnDisabled .button-native {
  background-color: red !important;
}

.cccLoginBtnEnabled {
  text-transform: none !important;
}

.cccOverViewCardContent {
  background-color: var(--ion-color-primary-contrast) !important;
}

.overview-points-text {
  font-family: 'Nunito';
  color: var(--ion-default-text-color) !important;
}

.ccaqOverviewTitletextDiv {
  padding-top: 8px;
  padding-bottom: 10px;
}

.cccTitleCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cccHeaderRow {
  display: flex;
  align-items: flex-start !important;
}

.cccExitCol {
  padding-left: 4px;
  padding-top: 4px;
}

ion-popover#ccc {
  --background: red !important;
  background-color: rgba(0, 0, 0, 0.8);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: var(--ion-color-primary-contrast);
  --border-radius: 16px;
}

.cccRewardsPopoverButtonsContainer {
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  align-items: center
}

/* .bg-color {
  --background: orange !important
} */


.reminder-title {
  color: var(--ion-modaltext);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-left: 10px;
}


.clock-img {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 0.667px;
  justify-content: center;
  align-items: center;
}

.overlay-modal-content-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-line {
  color: var(--ion-badge-text);
  height: 0;
  width: 100%;
  border-width: 2px;
}

.reminder-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remind-title {
  color: #364161;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 0px 16px 0px 16px;
}

.remind-titlenew {
  color: #364161;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: start;
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 50px;
  display: flex;
}

.remind-title-bold {
  font-weight: 700;
}

.sscpsReminderTimeRow {
  background-color: var(--ion-color-primary-contrast);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.sctabcheckedImgDiv {
  /* width: 100%; */
  margin-left: 10px;
  margin-right: 10px;
}


.sctabcheckedImg {
  width: 24px;
  height: 24px;
}

.sctabcheckedImg1 {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-left: 10px;
  align-items: center;
}

.reminder-options {
  background-color: var(--ion-palette-color1);
}

.reminder-radio-img {
  margin-top: 0;
  width: 24px;
  height: 24px;
}

.reminder-options-title {
  font-size: 16px;
  margin-left: 16px;
  line-height: 140%;
}

.sscpsReminderRadioDiv {
  margin-top: 0px;
  background-color: #f5f6fa;
  border-radius: 16px;
  display: flex;
  padding: 16px;
  padding-top: 0px;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
}

.sscpsReminderContent {
  --background: #f5f6fa;
}

.sscpsReminderContentNew {
  --background: #f5f6fa;
  width: 100%;
  height: 100%;
  /* height: 300px; */
  /* max-height: 60%;
  margin-top: 400px; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.sscpsReminderButtonsText {
  margin-left: 8px;
  font-size: 16px;
}

.sscpsReminderSummarytext {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333;
}

.sscpsopenSummaryRow {
  padding-left: 16px;
  padding-right: 16px;
}

.sscpsopenSummaryTextlast {
  color: #364161;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.sscpsopenSummaryImage {
  width: 220px;
}

.sscpsopensummarybtndiv {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--color-light, #CCC);
  background: var(--color-white, #FFF);
}

.sscpsopensummarybtnText {
  color: #333;
  text-align: center;
  /* Medium/Small */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.sscpsopensummaryimgpad {
  padding-right: 2px;
  color: #333;
}

.sscpsopensummarytextdone {
  color: var(--color-white, #FFF);
  text-align: center;
  /* Regular/Large */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
}

.sscpsccoImg {
  padding-right: 4px;
}

.sscpsBorderSel {
  border: 2px solid #003241;
}

.popover .popover-content {
  background: var(--color-white, #FFF);
  width: 100% !important;
  height: 100% !important;
}

.sseImage{
  width: 150px;
  height: 150px;
}

.sseImageDiv{
  
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}