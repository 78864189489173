.caidTextHeading{
    color: var(--ion-heading-text-color);
/* Bold/Lable */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
}
.caidBottomMarAccordian{
    padding-bottom: 2px !important;
}
.caidColImgheading{
    padding-right: 16px;
}
.caidColHeading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.caidGridMar{
padding: 16px;
gap: 10px;
border-radius: 16px;
background: var(--ion-color-light);
margin-bottom: 14px;
}

.caidColMar{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 16px; */
}
.caidDescText{
    color: var(--ion-default-text-color);
/* Medium/Small */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
.caidHeadText{
    color: var(--ion-default-text-color);
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}
.caidmarUl{
    margin-left: 16px;
    color: var(--ion-default-text-color);
}