.tsoMainImg {
  width: 60px;
}

.tsoTitleCol {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.tsoTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-heading-text-color);
}

.tsoSubTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--ion-heading-text-color);
}

.tsoDescCol {
  padding-top: 12px;
}

.tsoDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-tertiary-contrast);
}

.tsoMainContainer {
  background-color: var(--ion-color-primary-contrast) !important;
}

.tsoImgCol {
    padding-top: 16px;
}
