.bmcqmcoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.bmcqmcoTextDisabled {
  opacity: 0.5;
}

.bmcqmcoMainContainerSelected {
  background-color: var(--ion-color-primary-contrast);
  border: 2px solid var(--ion-color-success);
}
