.profileTitleRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileUsernameText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.profileMainContent {
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}

.profileUsernameCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.profileEditBtnCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px !important;
}

.profileEditBtn {
  width: 80px !important;
  min-height: 0px !important;
  height: 30px !important;
}

.profile-title-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}
