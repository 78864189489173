.coreFnfMainContainer {
    padding-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 16px;
}

.coreFnfRow {
    margin-bottom: 24px;
}

.coreFnfTitle {
    font-family: Nunito;
    font-size: 18px;
    font-style: bold;
    font-weight: 700;
    color: var(--ion-color-tertiary-contrast);
}

.coreFnfDesc {
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color:var(--ion-color-tertiary-contrast);
}

.coreFnfProgressCol {
    display: flex;
}

.coreFnfQuesNo {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--ion-color-tertiary-contrast);
}

.hcpbMainContainer {
    margin-top: 8px;
    /* margin-left: 16px; */
    border-radius: 24px;
}