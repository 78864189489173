ion-popover {
  --background: var(--ion-heading-text-color);
  --backdrop-opacity: 0.6;
  --box-shadow: none;
  --color: var(--ion-color-primary-contrast);
  --width: 300px;
}

ion-popover ion-content {
  --background: var(--ion-heading-text-color);
}

ion-popover::part(backdrop) {
  background-color: rgba(255, 255, 255, 0.4);
}

ion-popover::part(background) {
  background: var(--ion-heading-text-color)
}

:root {
  --background: var(--ion-color-primary-contrast);
}

.bg-color {
  --background: var(--ion-heading-text-color);
  background: var(--ion-heading-text-color);
}

.custom-nav {
  position: absolute;
  width: 250px;
  right: 10px;
  max-width: 300px;
}

.left-angle {
  width: 20px;
  height: 20px;
}

.hamburgerMC {
  cursor: pointer;
}

.absImageSave {
  width: 20px;
  margin-right: 8px;
}

.triangle-arrow {
  z-index: 9999;
  position: absolute;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--ion-heading-text-color);
  display: inline-block;
  top: -10px;
}

.save-spot-custom-nav {
  position: absolute;
  width: 285px;
  right: 10px;
  max-width: 300px;
  margin-top: 10px;
  margin-right: -8px;
}

.save-spot-custom-nav ion-list {
  border-radius: 8px;
  margin-right: -8px;
}

.save-spot-custom-nav ion-item {
  color: var(--color-white, #FFF);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}