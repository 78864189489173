@import "~animate.css/animate.css";
.toolbar-title-default {
  --background: var(--ion-color-primary);
  text-align: center;
  --min-height: 88px;
}
.title-default {
  --color: var(--ion-text-color);
  --font-family: var(--ion-font-family);
  --font-size: var(--h2-font-size);
  font-style: normal;
  font-weight: 700;
}

* {
  --font-family: "Nunito";
}

@media (min-width: 768px) {
  .ion-page {
    width: 565px;
    margin: auto;
  }
}

ion-content {
  --padding-top: 29px;
}

ion-col {
  padding: 0px;
}

ion-grid {
  padding: 0px;
}

.text-bold {
  font-weight: bold !important;
}

.default-padding-top {
  padding-top: 8px;
}
.default-padding-bottom {
  padding-bottom: 8px;
}

.default-font {
  font-size: 16px;
  font-weight: 400;
}
.mb-24 {
  margin-bottom: 24px;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-32 {
  padding-right: 32px !important;
}
.pl-32 {
  padding-left: 32px !important;
}

ion-card {
  margin-inline: 0px !important;
}
.text-default {
  color: var(--ion-color-tertiary-contrast);
}
.mss-custom-image {
  max-width: 112px;
  margin-bottom: 32px;
}

.mss-status-img {
  max-width: 240px;
}

.global-header {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: var(--ion-textcolor-accordion);
}

.font-bold {
  font-weight: 900;
}
