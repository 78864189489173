
.hscColCores {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid var(--ion-bgcolor-summary);
    background: var(--ion-color-primary-contrast);
    /* margin: 16px; */
}

.hscCheckMark {
    width: 20px;
    height: 20px;
}

.hscCoreText {
    color: var(--ion-badge-text);
    text-align: center;
    /* Bold/Small */
    font-size: 14px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-bottom: 4px;
}

.hscCoreDescText {
    color: var(--ion-color-tertiary-contrast);
    /* Bold/Lable */
    font-size: 16px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.hscImageCol {
    padding-right: 8px;
}

.hscRowImgPos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.hscColImg {
    display: flex;
    justify-content: end;
}
.hscImg{
    width: auto !important; 
     object-fit: fill;
    height: 100%;
}