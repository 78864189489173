.cc8Text {
  /* padding-left: 16px; */
  color: var(---ion-textcolor-accordion);
  color: var(--ion-default-text-color);
}

.ccTextChar {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: var(--ion-default-text-color);
}

.ccTextChar-bold {
  font-family: 'Nunito';
  font-style: normal;
  line-height: 25px;
  color: var(--ion-default-text-color);
  font-weight: 900 !important;
  font-size: 18px;
}
.cc3Row{
  color:bisque;
  border-bottom: 16px;
}
.cc3TextCol{
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
}
