.cccitMainContainerLeft {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  background-color: var(--ion-color-tertiary);
  padding: 0px;
  padding-right: 16px;
  border-radius: 16px;
}

.cccitMainContainerRight {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background-color: var(--ion-color-tertiary);
  padding: 0px;
  padding-left: 16px;
}

.cccitTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-heading-text-color);
}

.cccitDesc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-textcolor-accordion);
}

.cccitTitleCol {
  padding-top: 16px;
}

.cccitDescCol {
  padding-top: 8px;
}

.cccitAbsImgLeft {
  width: 60px;
  position: absolute;
  top: 18px;
  left: 30px;
}

.cccitAbsImgRight {
  width: 60px;
  position: absolute;
  top: 18px;
  right: 40px;
}

.cccitRightTriImg {
    width: 82px;
}
