.scdsub-heading {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  color: var(--ion-color-tertiary-contrast);
}

.scdsub-heading1 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* or 22px */
  color: var(--ion-default-text-color);

}

.scdsub-heading2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--ion-color-step-200);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 12px;
}

.scdFloatingdiv {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 90px;
  right: 16px;
  z-index: 1000;
}

.scdCol {
  padding-top: 16px;
}

.scdRow2 {
  padding-top: 24px;
}

.scdDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.scdDiaTextFieldDiv {
  width: 100%;
  height: 45px;
  border-bottom: 2px solid var(--ion-color-secondary);
}

.scdCol3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
}

.scdText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: var(--ion-skincheckprogressbar);
}

.scdText2 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: var(--ion-color-tertiary-contrast);
}

.scdGrid3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  gap: 24px;
  width: 100%;
  background: var(--ion-palette-color1);
  border-radius: 16px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.scdImg2 {
  width: 170px;
  top: 71px;

  padding-top: 24px;
  padding-bottom: 24px;
}

.scdCol4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 100%;
  background: var(--ion-color-light);
  border-radius: 16px;
  margin-bottom: 16px;
}
.scdCol5{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.scdText1{
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: var(--ion-progress-text);
}
.scdInput{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: var(--ion-skincheckprogressbar);
  width: 46px;
  pointer-events: none;
}
.scsdAccurateTextCol {
  display: flex;
  justify-content: center;
}

.loginBtnEnabled button {
  background: var(--ion-color-secondary);
}