.itfUsernameIconUnderlineContainer {
  width: 100%;
  height: 45px;
  border-bottom: 2px solid var(--ion-badge-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.itfTextField {
  border-radius: 0px;
  border-bottom: 2px solid var(--ion-badge-text);
  text-align: left;
  padding-left: 8px;
  color: var(--ion-heading-text-color);
  --placeholder-color: var(--ion-badge-text);

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.sc-ion-input-md-h {
  --padding-top: 9px;
}
.itfTextFieldContainer {
  width: 100%;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* padding-left: 4px; */
}
