.ctMainContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-top: 40px;
}

/* ion-popover#factPopover {
  --background: #f5f6fa !important;
}

ion-popover#factPopover ion-content {
  --background: f5f6fa !important;
}

.factPopoverText {
  color: blue;
} */

/* ///////////// */

/*
* Custom Datetime Day Parts
* -------------------------------------------
*/

/* ion-datetime {
  --background: #ffffff;
  --background-rgb: 255, 255, 255;
  --title-color: blue;
  
  --ion-color-step-650: #333333;
  --ion-color-step-500: purple;
  --ion-text-color: #333333;
  color: #333;
} */

