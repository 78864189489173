#target-element {
  width: 0px !important;
  height: 0px !important;
}

.custom-skincheckbuttons {
  position: absolute;
  right: 20px;
}

.small-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.body-checkimage {
  width: 80%;
  margin: 0 auto;
}

.destext {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  /* Primary/3 */
  color: var(--ion-color-secondary);
}

.skin-check-container {
  margin-top: 10px;
  margin-bottom: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.skin-selfcheck-Content {
  background: var(--ion-color-light) !important;
  padding: 40px 16px 16px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  min-height: 100%;
  margin-top: 54px !important;
}

.selfcheckContainer {
  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
}

.selfcheckContainer+.selfcheckContainer {
  margin-top: 16px;
}

.checklist-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-heading-text-color);
}

.group .group-title .group-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-progress-text);
}

.group+.group {
  margin-top: 24px;
}

.floatingdiv {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 16px;
  z-index: 1000;
  background: var(--ion-color-primary-contrast);
  border-radius: 50%;
}

.custom-popover,
.custom-popover ion-content::part(background) {
  --background: var(--ion-heading-text-color);
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

.scroll-container .indicator {
  height: 100%;
  background: var(--ion-scrollindicator);
}

.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--ion-scrollindicator);
  color: var(--ion-color-primary-contrast);
  z-index: 1;
}

.scroll-to-top .icon {
  margin-top: 10px;
  -webkit-animation: scrollTop 0.5s alternate ease infinite;
  animation: scrollTop 0.5s alternate ease infinite;
}

@-webkit-keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}

@keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}

.custom-youtube-player {
  position: fixed;
  width: 450px;
  height: 300px;
  bottom: 40px;
  right: 40px;
  background: var(--ion-color-tertiary-contrast);
}

.custom-youtube-player .close,
.custom-youtube-player .handle {
  position: absolute;
  right: 0px;
  top: -30px;
  width: 32px;
  height: 32px;
  background: var(--ion-color-tertiary-contrast);
  color: var(--ion-color-secondary-contrast);
  opacity: 1;
  padding: 3px;
  cursor: pointer;
}

.custom-youtube-player .handle {
  right: 32px;
  padding-top: 8px;
  cursor: move;
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--ion-fpContainer);
}

.fp-container .fp-loader {
  top: 30%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}

.battery-outer {
  height: 210px;
  width: 100px;
  border: 2px solid var(--ion-color-dark2);
  padding: 4px;
  border-radius: 7px;
}

.battery-inner {
  width: 100%;
  margin: 2px 0;
  background-color: var(--ion-batteryInner);
}

.battery-empty .battery-inner {
  background: var(--ion-scrollindicator);
}

.battery-outer::before {
  content: "";
  width: 24px;
  position: absolute;
  top: -8px;
  height: 8px;
  background: var(--ion-color-dark2);
  left: 49%;
  border-radius: 4px 4px 3px 3px;
}

.battery-outer .battery-charging-indicator {
  position: absolute;
  top: 40%;
  left: 49.2%;
  color: var(--ion-batteryOuter);
  font-size: 40px;
  display: none;
}

.battery-charging .battery-outer .battery-charging-indicator {
  display: block;
  -webkit-animation: fadeIn 0.8s infinite alternate;
  animation: fadeIn 0.8s infinite alternate;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.password-toogle-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.headingText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 16px !important;
  color: var(--ion-heading-text-color);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.sctSpotNoDiv {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--ion-textnumbrer);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scscSpotsText {
  margin-left: 15px;
  margin-right: 13px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-skincheckprogressbar);
}

.scscSpotsNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  color: var(--ion-color-primary-contrast);
}

.sctSpotsNoCol {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.sctSVGContainerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.footer-button {
  background: var(--ion-color-light) !important;
  box-shadow: 0px 0px 0px rgba(31, 33, 51, 0.11) !important;
}

.sctabHeader {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}
.sctabHeader1 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}
.sctabHeader2 {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--ion-textcolor-accordion);
}
.sctabMainHeadingRow{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sctabcheckedImgDiv{
  /* width: 100%; */
  margin-left: 10px;
  margin-right: 10px;
}
.sctabcheckedImg{
  width: 24px;
  height: 24px;
}