.accbeMainContainer {
  margin-top: 24px;
}

.acceBioText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.acceBioTextVal {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.acceBioDemographicsRow {
    margin-top: 24px;
}

ul {
    list-style-type: disc;
    color: black;
    margin: 0px;
    padding: 0px;
    padding-left: 16px;
}

li {
    margin-top: 8px;
}
