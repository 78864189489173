.spriteMainContainer {
  width: 100%;
  height: 100%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noSpotDiv {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-textnumbrer);

  position: absolute;
  left: 50px;
  top: 50px;
}

.noSpotNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-color-primary-contrast);
}

.sprite-div {
  background-color: var(--ion-color-primary-contrast);
  width: 300px;
  height: 300px;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.my-svg-alternate {
  display: none;
}

.no-svg .my-svg-alternate {
  display: block;
  /* width: 100px;
  height: 100px; */
  /* background-image: url(image.svg); */
}
