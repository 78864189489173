.scsdFloatingdiv {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 90px;
    right: 16px;
    z-index: 1000;
}

.scsdMainContent {
    background-color: var(--ion-color-light) !important;
}

.scsdTextPad {
    padding: 0px;
    background-color: transparent;
    margin-top: 4px;
}

.scsdsub-heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 31px;
    color: var(--ion-textcolor-accordion);
}

.scsdsub-heading1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--ion-color-step-200);
    ;
}

.scsdsub-headingBold {
    font-weight: 700;
}

.scsdRow {
    margin-top: 24px;
}

.scsdCol1Even {
    height: 135px;
    width: 147px;
    border-radius: 12px;
    margin-bottom: 8px;
    padding-right: 4px;
}

.scsdCol1Odd {
    height: 135px;
    width: 147px;
    border-radius: 12px;
    margin-bottom: 8px;
    padding-left: 4px;
}

.scsdOptionDiv {
    background-color: var(--ion-color-primary-contrast);
    border-radius: 16px;
    padding: 8px;
    height: 100%;
    width: 100%;
}

.scsdSubDiv {
    background-color: aqua;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 16px;
    height: 84px;
    margin-bottom: 8px;
}

.scsdImg {
    width: 70.27px;
    -o-object-fit: fill;
       object-fit: fill;
    /* height: 64.63px; */
}

.scsdTextChar {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: var(--ion-default-text-color);
}

.scsdTextChar-bold {
    font-family: 'Nunito';
    font-style: normal;
    line-height: 25px;
    color: var(--ion-default-text-color);
    font-weight: 900 !important;
    font-size: 18px;
}

.scsdCheck {
    position: absolute;
    left: 8px;
    top: 8px;

}

.scsdNextBtn {
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
}

.scsdQuestionImg {
    width: 22px;
    display: inline-block;
    position: relative;
    top: 4px;
    left: 5px;
}

.spot-details ion-backdrop {
    opacity: 0.5;
    background: var(--ion-color-tertiary-contrast);
    z-index: 400;
}

#scscModal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    z-index: 10000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: var(--ion-color-primary-contrast);
    width: 90%;
    /* height: 100px; */
    border-radius: 10px;
}
.scscBD{
    background: rgba(31, 33, 51, 0.3);
}
.scsdGrid3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 24px;
    width: 100%;
    border-radius: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.scsdText5 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    color: var(--ion-textcolor-accordion);
}

.scsdText6 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--ion-default-text-color);
}

.scsdText7 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--ion-progress-text);
}

.scsdCol5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 23px;
}

.scsdCol6 {
    margin-top: 12px;
    justify-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.scsdDiv5 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 43px;
}

.scsdImg2 {
    width: 183px;

}

.scsdGridUpdate {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.scsdDiv7 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 28px;
}

.scsdDiv8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
}

.scsdDiv9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    background: var(--ion-color-primary-contrast);
    -webkit-box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
            box-shadow: 0px -4px 15px rgba(31, 33, 51, 0.11);
    margin-top: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.scsdTitle{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.scsdRightButton {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--ion-color-primary-contrast);
}
.scsdColRight{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.scsdOptionDivSelected {
    border: 2px solid #003241;
}

ion-popover {
    --background: var(--ion-color-success);
    --backdrop-opacity: 0.6;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
    --color: var(--ion-color-primary-contrast);
    --border-radius: 16px;
  }
  
  ion-popover ion-content {
    --background: var(--ion-popover-content);
  }
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 80px;
  }

  .scsdDownloadPDFText1 {
    color: var(--ion-color-primary-contrast);
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .scsdDownloadPDFText2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--ion-link-text-color);
    margin-top: 4px;
  }