.petfPasswordFieldDiv {
    padding-top: 14px;
}

.profilesaveBtn {
    width: 100%;
    --border-radius: 24px;
    font-family: "Nunito";
    font-size: 18px;
    font-weight: 700;
    color: var(--ion-text-color);
    opacity: 0.5;
    line-height: 25.2px;
    text-transform: capitalize;
    min-height: 49px;
  }