.sceImg {
  width: 280px;
  height: 194px;
}

.sceImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sceMainTextDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.sceMainText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: var(--ion-textcolor-accordion);
}

.sceMainText2Div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.sceMainText2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.sceMainText3Div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.sceMainText3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.sceMainText3Div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sceMainText3NoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-textnumbrer);
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.sceMainText3No {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sceContinueImg {
    margin-right: 9px;
    width: 22px;
}