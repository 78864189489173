.ccaoTitleText {
    font-family: "Nunito";
    font-size: 16px;
    font-weight: 700;
    color: var(--ion-default-text-color);
    line-height: 22.4px;
    max-lines: 0;
}

.ccaoTitleTextSelected {
    font-family: "Nunito";
    font-size: 16px;
    font-weight: 700;
    color: var(--ion-color-primary-contrast);
    line-height: 22.4px;
    max-lines: 0;
}

.accqTitleCol {
    display: flex;
    align-items: center;
}

.ccaoMainContainerSelectedCorrect {
    border: 2px solid var(--ion-color-success);
    background-color: var(--ion-bg-correctselection);
}

.ccaoMainContainerSelectedWrong {
    border: 2px solid var(--ion-color-success);
    background-color: var(--ion-color-danger);
}