.fpQuestion {
  color: var(--ion-color-tertiary-contrast);
  font-family: "Nunito";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.fpQuestionDesc {
  color: var(--ion-textcolor-accordion);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.fpQuestionDescContainer {
  padding-top: 16px;
}

.fpMainContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-top: 36px;
}
