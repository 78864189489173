.hcpbMainContainer {
  width: 100%;
  height: 5px;
  background-color: var(--ion-core-containerbg);
  border-radius: 8px;
}

.hcpbFillContainer {
  height: 5px;
  background-color: var(--ion-color-danger);
  border-radius: 8px;
}
