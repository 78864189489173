.rGrid{
    padding-top: 24px;
}
elcomeText{
    color: var(--ion-heading-text-color);
font-family: Nunito;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 44.8px */
}
.rRegisterText{
    color: var(--ion-heading-text-color);
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%;
}
.rColText{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}