.likert-component {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9999;
}
.likert-bar {
    width: 100%;
    height: 5px;
    background-color: #005C6C;
    position: absolute;
    top: 12px;
    z-index: -1;
}
.likert-icon-3 {
  /* margin-top: -5px; */
  width: 30px;
}
.likert-icon-4 {
  margin-top: 4px;
}
.likert-pointer {
  width: 16px;
  margin-top: 12px;
  margin-left: 12px;
}
.likert-pointer-3 {
  width: 16px;
  margin-top: 12px;
  margin-left: 18px;
}
.likert-pointer-4 {
  width: 16px;
  margin-top: 12px;
  margin-left: 13px;
}

.likert-desc-text {
  color: #364161;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 4px;
}
