.srsTextCol {
  padding-top: 22px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.srsText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-heading-text-color);
}

.srsTextMainDiv {
  position: relative;
  background-color: var(--ion-palette-color1);
  margin-top: 32px;
  margin-bottom: 16px;
  border-radius: 16px;
}

.srsStarsDivMain {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -16px;
  height: 32px;
  width: 100%;
}

.srsStarsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: var(--ion-color-secondary);
    border-radius: 26px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
}

.srsStarImg {
    width: 18px;
}
