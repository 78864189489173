.sccpNextBtn {
  width: 50% !important;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sccpCapturedImg {
  width: 100%;
  margin-top: 32px;
}

.sccpRetakeBtnDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.sccpRetakeBtn {
  height: 40px !important;
  width: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sccpCancelBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.sccpMar {
  margin-top: 56px;
}

.sccpsDescText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  color: var(--ion-textcolor-accordion);

}
.sccpText{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
text-align: center;
color: var(--ion-color-primary-contrast);
}
