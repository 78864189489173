.spsMainDiv {
  position: relative;
  background-color: var(--ion-color-tertiary);
  /* margin-top: 32px;
  margin-bottom: 16px; */
  border-radius: 16px;
  padding: 16px;
}

.spsText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-heading-text-color);
}

.spsTextCol {
  padding: 0px;
  margin-bottom: 10px;
}

.spsPointsImg {
  width: 16px;
  margin-top: 3px;
}

.spsPointTextCol {
  padding-left: 8px;
}

.spsPointText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.spsMainRow {
  display: flex;
  align-items: flex-start;
}
.spsMargin{
  margin-bottom: 10px;
}
.spsTextColRem{
  padding: 0px;
}