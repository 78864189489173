.date-picker-main {
  padding: 0 8px 0 8px;
}

.dateTime-picker {
  border-radius: 12px;
  background-color: #F5F6FA;
  color: #333;
  margin-left: 8px;
  margin-right: 8px;
  width: 347px;
  margin-top: -11px;
  margin-bottom: 9px;
}

ion-datetime {
  /* --background: #ffffff; */
  --wheel-highlight-background: rgb(218, 216, 255);
  --wheel-fade-background-rgb: 245, 235, 247;
  /* --background-rgb: 255, 255, 255; */
  --title-color: blue;
  --ion-color-step-650: #333;
  --ion-color-step-500: purple;
  --ion-text-color: #333;
  color: #333;
}

ion-popover {
  --background: #F5F6FA;
  --ion-color-step-150: rgba(116, 116, 128, 0.08);
  color: #333;
}

.dateTime-picker-time {
  border-radius: 12px;
  margin-top: -39px;
  margin-left: 8px;
  margin-right: 8px;
}

.time-picker-main-div {
  background-color: #F5F6FA;
  width: 347px;
  margin: -10px 0 0 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 70px;
  display: flex;
  justify-content: space-between;
}

.time-picker-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  height: 30px;
  border-radius: 8px;
  padding: 8px;
}

.time-picker {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* margin-right: 20px; */
}

.time-title {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-left: 12px;
}