.accccTitleGrid {
  margin-bottom: 0px !important;
}

.accccTitleCol {
  display: flex;
  align-items: center;
}

.accccChevronCol {
  align-items: center;
}

.accccMainContainer {
  background-color: var(--ion-color-primary-contrast);
  margin-bottom: 16px;
}

.accDescTextContainer {
  padding-top: 24px;
}

.accDescText {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-default-text-color);
  line-height: 22.4px;
  max-lines: 0;
}

.accAudioDescBtnsGrid {
  margin-top: 16px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #f5f6fa;
  height: 50px;
}

.accAudioDescBtnsDiv {
  padding-right: 4px;
  width: 100%;
}

.accShowText {
  color: #005C6C;
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
  text-decoration: underline;
}

.accShowTextCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accVolumeBtnDiv {
  width: 150px;
  height: 36px;
  background-color: var(--ion-color-primary);
  border-radius: 24px;
  padding: 7px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.accVolumeImg {
  width: 20px;
  height: 20px;
}

.accVolumeBtnTextDiv {
  height: 100%;
  /* background-color: black; */
  display: flex;
  align-items: center;
}

.accVolumeBtnText {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
}
