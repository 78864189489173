.msgBgGrid {
    /* display: flex; */
    padding: 16px;
    /* align-items: flex-start; */
    gap: 16px;
    border-radius: 16px;
    background: var(--ion-color-light);
}

.msgHeadingText {
    color: var(--ion-color-light-contrast);
    /* Bold/Heading 4 */
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
}

.msgHeadingText1 {
    display: flex;
    align-items: flex-start;
    color: var(--ion-textcolor-accordion);
    /* Bold/Lable */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    z-index: 9999;
    position: absolute;
    padding: 4px;
    /* 22.4px */


}

.msgColbg {
    border-radius: 16px;
    background: var(--ion-color-primary-contrast);
    /* margin-right: 16px; */
    /* margin-top: 16px; */
    border-radius: 16px;
    padding: 16px;
    height: 135px;
    display: flex;
    position: sticky;
    justify-content: center;
    /* align-items: center;  */
}

.msgImgDiv {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: greenyellow; */
}

.msgImgProp {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 93.534px; */
    z-index: -9999;
    opacity: 0.25;
    /* height: 93.534px; */
    /* padding: 10.186px 15.271px 0.062px 11.866px; */
}

.msColbgMar {
    margin-top: 16px;
}

.msgTextBtn {
    color: var(--ion-color-step-200);
    text-align: center;
    /* Regular/Extra Small */
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 18.2px */
}

.msgBtnBg {}

.msgTextDiv {
    /* width: 100%; */
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid var(--ion-color-step-800);
    background: var(--ion-color-primary-contrast);
    position: absolute;
    bottom: 16px;

}