.sdFont {
    color: #364161;
    /* Medium/Small */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
}

.sdDiv {
    display: flex;
    padding: 16px;
    gap: 10px;
    border-radius: 16px;
    background: var(--blue-8, #FFF);
}