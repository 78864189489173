.accMainContainer {
  background-color: var(--ion-color-light);
  /* margin-top: 24px; */
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 20px;
  padding: 16px;
}
.accMainContainer + .accMainContainer {

  margin-top: 16px;
}

.accTitleCol {
  padding-left: 16px;
}

.accTitleText {
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-tertiary-contrast);
  line-height: 25.2px;
  max-lines: 0;
}

.accContentText {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-tertiary-contrast);
  line-height: 22.4px;
  max-lines: 0;
}

.accChevronCol {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

ion-icon.accChevronIcon {
  color: var(--ion-textcolor-accordion);
  width: 30px;
  height: 25px;
}

.accLessonImg {
  width: 66px;
  height: 66px;
  border-radius: 12px;
  background-color: var(--ion-lessonimg-accordion);
}

ion-grid.accTitleGrid {
    margin-bottom: 8px;
}
