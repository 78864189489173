.ascteText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--ion-textcolor-accordion);
}

.ascteMainContainerColor1 {
  background-color: var(--ion-palette-color1);
}

.ascteMainContainerColor2 {
  background-color: var(--ion-color-tertiary);
}
