.loginBtnEnabled.mss-profile {
  width: 100%;
}
.default-list {
  margin: 0;
  background: none;
}
ion-item.default-list {
  --ion-background-color: transparent !important;
  --padding-left:0;
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  --inner-border-width: 0;
}
ion-label.default-text {
  font-family: var(--ion-font-family)!important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: var(--ion-color-tertiary-contrast)!important;
}

.default-icon{
  width: 20px;
  height: 20px;
}
ion-button.mss-defaultbutton{
  --border-radius: 24px;
  --padding: 5px 12px;
  --line-height: 19.6px;
  --height: 30px;
  --padding-start: 12px;
  --padding-end: 12px;height: 30px;
}

.contactLabel {
  margin-left: 8px !important;
}