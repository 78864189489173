.titleText {
  font-family: "Nunito";
  font-size: 22px;
  font-weight: 700;
  color: var(--ion-color-primary-contrast);
  line-height: 30.8px;
}
.titleCenter{
  display: flex;
  justify-content: center !important;
}

.mainContent {
  /* position: absolute; */
  margin-top: 29px;
  padding-top: 4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 90px;
  background-color: var(--ion-color-primary-contrast);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  min-height: 100%;
  padding-bottom: 24px;
}

ion-icon.settingsIcon {
  color: var(--ion-color-primary-contrast);
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
