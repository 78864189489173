/* @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    src: url("../public/assets/fonts/Nunito-VariableFont_wght.ttf");
  }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    src: url("../public/assets/fonts/Roboto/Roboto-Regular.ttf");
  } */

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    src: url("../public/assets/fonts/Inter-Regular.ttf");
  }