.primary-button {
  background-color: var(--ion-color-secondary);
  border: 1px solid var(--ion-progress-text);
  border-radius: 4px 0px 0px 4px;
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  color: var(--blue-8, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  height: auto;
}

.scz-main-div {
  min-height: 100%;
  min-width: 100%;
  padding: 0 16px 16px 16px;
}

.sez-title {
  color: var(--ion-textcolor-accordion);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.sez-grid-div {
  min-height: 100%;
  min-width: 100%;
  padding: 16px;
  background-color: #fff;
}

.sez-button-row {
  padding-bottom: 16px;
}

.zones-svg {
  position: relative;
  background-color: var(--ion-color-primary-contrast);
  width: 310px;
}

.sez-svg-row {
  display: flex;
  justify-content: center;
}

.sez-title-div {
  margin-bottom: 16px;
}

.sez-select-text {
  color: var(--ion-textcolor-accordion);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding-bottom: 16px;
}

.sez-select-col {
  padding-bottom: 16px;
}

.secondary-button {
  color: black;
  border: 1px solid var(--blue-4, #4f5a7f);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--ion-color-primary-contrast);
}

.scz-continue-btn {
  margin-top: 10px;
}

.scz-zone-btn {
  background-color: var(--ion-color-primary-contrast);
  position: absolute;
  padding: 2.5px 10px;
  border: 1px solid var(--ion-color-step-800);
  border-radius: 24px;

  color: var(--color-text, #333);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
