
.hcpChapterProgressImage {
    width: 24px;
    height: 24px;
}

.hcpCoreText {
    color: var(--ion-progress-text);
    text-align: center;
    /* Bold/Small */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-left: 8px;
}


.hcpChapterText {
    color: var(--ion-color-tertiary-contrast);
    /* Bold/Lable */
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.hcpRightIconCol {
    margin: auto;
}

.hcpProgressText {
    color: var(--ion-color-tertiary-contrast);
    /* Medium/Lable */
    font-family: Nunito;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.hcpColCores {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--ion-bgcolor-summary);
    background: var(--ion-color-primary-contrast);
    /* margin: 16px; */
}
.hcpImageCol{
    padding-right: 16px;
    /* display: contents; */
}
.hcpImage{
     width: auto !important; 
     object-fit: fill;
    /* height: 100%; */
}
.hcpProgressbar{
    padding-top: 16px;
    padding-bottom: 4px;
}