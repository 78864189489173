.sscpsDescText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
}

.sscpsDescText-bold {
  font-weight: 900;
}

.sscpsPartImg {
  margin-top: 8px;
  margin-bottom: 24px;
}

.sscpsDescText2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-textcolor-accordion);
  margin-top: 24px;
}

.sscpsMainContent {
  background-color: var(--ion-color-light);
  padding-bottom: 127px;
}

.sscpsOptionRightBtnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 117px;
  border-radius: 12px;
  background-color: var(--ion-color-secondary);
}

.sscpsOptionRightBtnText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpOptionCol {
  display: flex;
  align-items: center;
}

ion-button.sscpsButton12 {
  --background: var(--ion-color-primary-contrast);
  --border-radius: 24px;
  border-radius: 24px;
  height: 30px;
  border: 1px solid var(--ion-scpartselected);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--ion-color-step-200);
}

.ffsscpsButton12Col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ffsscpsButtonNext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpsSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sscpsSpotNoDiv {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--ion-color-success);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.sscpsSpotNoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.sscpsSpotNoContainerDiv {
  position: relative;
}

.sscpsSvg {
  width: 250px;
  height: 150px;
}

.sscpsRegionDiv {
  width: 100%;
  /* height: 200px; */
  background-color: var(--ion-color-primary-contrast);
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px;
}

.sscpsRegionText {
  margin-bottom: 16px;
  color: var(--ion-color-tertiary-contrast);
}

.selfcheckContainerRegion {
  background: var(--ion-color-light);
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
}

.selfcheckContainerRegionWhite {
  background: var(--ion-color-primary-contrast);
  border-radius: 16px;
  padding: 16px;
  margin-top: 0px;
}

.sscpsWarningText {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 10px;
  border-radius: 16px;
  background: var(--primary-6, #E0F6FD);
}
.sscpsWarningTextFont{
  color: #000;
/* Medium/Small */
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
padding-left: 16px;
padding-right: 16px;
padding-top: 10px;
padding-bottom: 10px;
}