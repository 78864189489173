.subAccMainContainer {
    width: 100%;
    background-color: var(--ion-color-primary-contrast);
    margin-top: 16px;
    border-radius: 16px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.subAccChevronCol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-items: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.subAccLeftIconDiv {
    /* background-color: brown; */
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

ion-row.subAccTitleRow {
    margin-bottom: 4px;
}

.subAccChapterNoText {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-heading-text-color);
    line-height: 19.6px;
    max-lines: 0;
}

.subAccChapterTitleText {
    font-family: "Nunito";
    font-size: 16px;
    font-weight: 700;
    color: var(--ion-heading-text-color);
    line-height: 22.4px;
    max-lines: 0;
}

.subAccLeftIcon {
    width: 20px;
    height: 20px;
    color: var(--ion-heading-text-color);
    margin-right: 8px;
}

.subAccRightIcon {
    width: 15px;
    height: 15px;
    color: var(--ion-heading-text-color);
}