.hcsMainGrid {
    display: flex;
    /* width: 343px; */
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    background: var(--ion-color-light);
}

.hcsTitleText {
    color: var(--ion-color-tertiary-contrast);
    text-align: center;
    /* Bold/Heading 3 */
    font-size: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.hcsSubGrid {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--ion-color-primary-contrast);
    margin-left: 0px;
    margin-right: 0px;
}

.hcsSubRow {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.hcsSubText {
    color: var(--ion-color-tertiary-contrast);
    font-size: 18px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}
.hcsSubCol{
    width: 100%;
}

.hcsButtonStart {
    display: flex;
    height: 29px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 24px;
    border: 1px solid var(--ion-color-secondary);
    background: var(--ion-color-secondary);
    width: 100%;
}

.hcsBtnTextStart {
    color: var(--ion-color-primary-contrast);
    text-align: center;
    /* Regular/Small */
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.hcsButtonSet {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 29px;
    /* padding: 4px 12px; */
    gap: 8px;
    border-radius: 24px;
    width: 100%;
    padding-left: 8px;
}

.hcsBtnTextSet {
    /* display: flex;
    justify-content: flex-end; */
    color: var(--ion-color-secondary);
    text-align: center;
    /* Regular/Small */
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}


.hcsButtonsRow{
    width: 100%;
}