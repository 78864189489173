.ilColimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ilGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 53px;
}
.ilRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.ilTextCol {
  margin-top: 42px;
  margin-bottom: 24px;
}
.ilColText {
  color: var(--ion-heading-text-color);
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 39.2px */
}
.ildescText {
  color: var(--ion-desc-textlogin);
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
}
.ilregText {
  color: var(--ion-link-text-color);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-left: 2px;
}
.ilBottomSpace {
  margin-bottom: 24px;
}
.loginBtnEnabled {
  width: 100%;
  --border-radius: 24px;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-text-color);
  line-height: 25.2px;
  text-transform: none !important;
  min-height: 49px;
}
.loginLoginBtn {
  width: 100% !important;
}
