/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #187585;
  --ion-default-text-color: #364161;
  --ion-color-primary-rgb: 24,117,133;
  --ion-color-primary-contrast: #FFFFFF;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #156775;
  --ion-color-primary-tint: #2F8391;
  /** secondary **/
  --ion-color-secondary: #005C6C;
  --ion-color-secondary-rgb: 0,92,108;
  --ion-color-secondary-contrast: #FFFFFF;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #00515F;
  --ion-color-secondary-tint: #1A6C7B;
  /** tertiary **/
  --ion-color-tertiary: #E0F6FD;
  --ion-color-tertiary-rgb: 224,246,253;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #C5D8DF;
  --ion-color-tertiary-tint: #E3F7FD;
  /** success **/
  --ion-color-success: #003241;
  --ion-color-success-rgb: 0,50,65;
  --ion-color-success-contrast: #FFFFFF;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #002C39;
  --ion-color-success-tint: #1A4754;
  /** warning **/
  --ion-color-warning: #FFC409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #E0AC08;
  --ion-color-warning-tint: #FFCA22;
  /** danger **/
  --ion-color-danger: #ED826E;
  --ion-color-danger-rgb: 237,130,110;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #D17261;
  --ion-color-danger-tint: #EF8F7D;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark2: #222;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #FFFFFF;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1E2023;
  --ion-color-dark-tint: #383A3E;
  /** medium **/
  --ion-color-medium: #92949C;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9D9FA6;
  /** light **/
  --ion-color-light: #F5F6FA;
  --ion-color-light-rgb: 245,246,250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #D8D8DC;
  --ion-color-light-tint: #F6F7FB;

/** My Smart Skin **/
  --ion-text-color:#ffffff;
  --ion-heading-text-color:#1f2133;
  --ion-link-description-color:#1f2133;
  --ion-link-text-color:#43899A;
  --ion-font-family:'Nunito';
  --default-font-size: '16px';
  --h1-font-size:'24px';
  --h2-font-size: 22px;
  --h3-font-size:'20px';
  --h4-font-size:'18px';
  --h5-font-size:'16px';
  --h6-font-size:'16px';
  --default-line-height:'22.4px';

  /** Summary **/
  --ion-bgcolor-summary:#E9EDF5;

--ion-textcolor-accordion:#2e3656;
--ion-color-step-200: #333333;
--ion-color-step-800: #cccccc;
--ion-titlediv-accordion:#4285f7;
--ion-lessonimg-accordion:#99c1f8;
--ion-bg-correctselection:#73adbb;
--ion-wrong-container:#F0A095;
--ion-count-color:#228090;
--ion-badge-text:#9DA2B2;
--ion-progress-text:#4f5a7f;
--ion-core-containerbg:#ffe3df;
--ion-main-container-homeintro:#e9f7fb;
--ion-skincheckprogressbar:#228091;
--ion-color-step-550: #8c8c8c;
--ion-desc-textlogin:#1e232c;
--ion-textnumbrer:#003241;
--ion-modaltext:#303F4A;
--ion-popover-content:#020044;
--ion-spotreview-text:#CD4E37;
--ion-scpartselected:#eeeeee;
--ion-scrollindicator:#8b0000;
--ion-fpContainer:#f8f8f8ad;
--ion-batteryInner: #01770a;
--ion-batteryOuter: #cbd71d;
  /** skin color Palette Colors**/

  --ion-palette-color1:#D8E7FD;
  --ion-palette-color2:#1F1410;
  --ion-palette-color3:#2E1B14;
  --ion-palette-color4:#3F271B;
  --ion-palette-color5:#7A4B22;
  --ion-palette-color6:#8D5524;
  --ion-palette-color7:#C68642;
  --ion-palette-color8:#F0B48F;
  --ion-palette-color9:#F5D4B3;
  --ion-palette-color10:#FAF1C8;

  --ion-color-custom: orange;
	--ion-color-custom-rgb: 255,184,0;
	--ion-color-custom-contrast: #fff;
	--ion-color-custom-contrast-rgb: 0,0,0;
	--ion-color-custom-shade: pink;
	--ion-color-custom-tint: yellow;
  --ion-color-custom-month: #333;
}

.ion-color-custom {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-custom-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-shade);
  --ion-color-tint: var(--ion-color-custom-tint);
  --ion-item-color: var(--ion-color-custom-month);
}


/* @media (prefers-color-scheme: dark) { */
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /* body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  } */


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */
