.forgot-password-desc-bold {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
}

.forgot-pass-sub-desc-row {
  margin-top: 8px;
}

.input-email-row {
  margin-top: 40px;
}

.input-close-icon {
  width: 20px;
  height: 20px;
  float: right;
  margin-top: 18px;
}

.email-validation-text {
  color: red;
}

.success-text {
  color: green;
  font-size: 18;
  font-family: "Nunito";
}

.error-text {
  color: red;
  font-size: 18;
  font-family: "Nunito";
}