.cceTitleImg {
    width: 233px;
}

.cceTitleImgCol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
}

.cceTitleText {
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 31px;
    color: #2e3656;
    inline-size: -webkit-max-content;
    inline-size: -moz-max-content;
    inline-size: max-content;
}

.cceTitleTextCol {
    padding-top: 22px;
}

.cceTitleTextCol1 {
    padding-top: 16px;
}

.cceTitleText1 {
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2e3656;
    inline-size: -webkit-max-content;
    inline-size: -moz-max-content;
    inline-size: max-content;
}

.cceTitleTextCol2 {
    padding-top: 8px;
}

.cceTitleText2 {
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25.2px;
    color: #364161;
    inline-size: -webkit-max-content;
    inline-size: -moz-max-content;
    inline-size: max-content;
}

.cceMainContent {
    padding-bottom: 100px;
}

.custom-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.loginBtnEnabled,
.loginBtnDefault {
    width: 50%;
}

.loginBtnEnabled {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

}

.loginBtnDefault {

    --border-radius: 24px;
    line-height: 25.2px;
    text-transform: capitalize;
    min-height: 49px;
    margin-right: 8px;
}

.loginBtnDefault {
    font-family: 'Roboto';
    line-height: 140%;
    --border-radius: 24px;
    --background: initial;
    --color: #333333;
    --border-width: 1px;
    --box-shadow: none;
    --border-color: #cccccc;
    border: 1px solid #cccccc;
    color: #333333;
    border-radius: 24px;
    background: none;
    font-size: 14px;
}

.custom-icon {
    margin-right: 10px;
    width: 21px;
    height: 21px;
}

@media screen and (min-width:768px) {
    .loginBtnEnabled {

        font-size: 18px;
    }

    .loginBtnDefault {

        font-weight: 400;
        font-size: 18px;
    }
}

.customswipeFooter {
    bottom: 70px !important;
    left: 0;
}









