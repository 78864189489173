.lmitMainContainer {
  background-color: var(--ion-color-primary-contrast) !important;
}

.lmitText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.lmitBtn {
  margin-top: 16px;
  color: var(--ion-color-secondary);
  border-radius: 16px;
  border: 1px solid var(--ion-color-secondary);
  padding: 4px 10px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--ion-color-secondary);
}
