.primary-button {
  background-color: var(--ion-color-secondary);
  border: 1px solid var(--ion-progress-text);
  border-radius: 4px 0px 0px 4px;
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  color: var(--blue-8, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  height: auto;
}

.scz-tour-image {
  width: 160px;
  height: 144px;
}

.scz-tour-skip-col {
  display: flex;
  width: 100% !important;
  height: 29px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.scz-tour-skip-button {
  width: 100%;
  height: 29px;
  border-radius: 24px;
  background: #fff;
}

.scz-tour-next-button {
  width: 100%;
  height: 29px;
  border-radius: 24px;
  background-color: #005C6C;
}

.scz-main-div {
  min-width: 100%;
  min-height: 100%;
  background-color: #003241;
  align-items: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
  align-self: stretch;
}

.scz-main-text-heading {
  color: var(--color-text, #333);
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  padding-top: 24px;
  font-weight: 400;
  line-height: 162%;
  align-items: center;
  letter-spacing: 1.6px;
}

.scz-main-text-subtext {
  color: rgba(51, 51, 51, 0.80);
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%;
  /* 25.92px */
  letter-spacing: 1.28px;
}

.scz-main-slide-1-row {
  width: 100%;
  flex-shrink: 0;
  margin-top: 50px;
  padding-left: 18px;
  padding-right: 18px;
}

.scz-main-slide-2-row {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 18px;
  padding-right: 18px;
  flex-direction: column;
  align-items: center;
  display: flex;
  align-self: stretch;
}

.scz-main-slide-main-row {
  width: 100%;
  flex-shrink: 0;
  padding-left: 18px;
  align-items: center;
  padding-right: 18px;
  align-items: center;
  flex-direction: column;
  display: flex;
  align-self: stretch;
}

.scz-main-text-thirdtext {
  color: var(--color-text, #333);
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
}

.scz-main-text-heading2 {
  color: var(--color-text, #333);
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  align-items: center;
  padding-top: 24px;
  font-weight: 700;
  line-height: 162%;
  letter-spacing: 1.6px;
}

.scz-main-slide2-subtext {
  color: var(--color-text, #333);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.scz-main-slide2-subtext2 {
  color: #333;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}

.scz-main-text-slide2mainheading {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
}

.scz-main-text-slide3mainheading {
  color: var(--color-text, #333);
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.scz-main-text-slid3-subtext {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 22.4px */
}


.scz-image-div {
  min-height: 100%;
  min-width: 100%;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
  align-self: stretch;
}

.scz-main-grid {
  position: fixed;
  align-items: center;
  bottom: 60px;
}

.scz-bottom-div {
  display: flex;
  padding-bottom: 16px;
  align-items: flex-start;
  box-shadow: 0px -1px 0px 0px #eee inset;
}

.scz-slide-sel-dot {
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 50%;
  background-color: var(--Dodger-Blue-1, #4285F7);
}

.scz-col {
  padding: 4px;
}

.scz-slide-unsel-dot {
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.sez-title {
  color: var(--ion-textcolor-accordion);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.sez-grid-div {
  min-height: 100%;
  min-width: 100%;
  padding: 16px;
  background-color: #fff;
}

.sez-button-row {
  padding-bottom: 16px;
}

.zones-svg {
  position: relative;
  background-color: var(--ion-color-primary-contrast);
  width: 310px;
}

.sez-svg-row {
  display: flex;
  justify-content: center;
}

.sez-title-div {
  margin-bottom: 16px;
}

.sez-select-text {
  color: var(--ion-textcolor-accordion);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding-bottom: 16px;
}

.sez-select-col {
  padding-bottom: 16px;
}

.secondary-button {
  color: black;
  border: 1px solid var(--blue-4, #4f5a7f);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--ion-color-primary-contrast);
}

.scz-continue-btn {
  margin-top: 10px;
}

.scz-zone-btn {
  background-color: var(--ion-color-primary-contrast);
  position: absolute;
  padding: 2.5px 10px;
  border: 1px solid var(--ion-color-step-800);
  border-radius: 24px;

  color: var(--color-text, #333);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.sczTextSkip {
  color: #005C6C;
  text-align: center;
  /* Regular/Small */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}
.sczColRow{
  display: flex;
  align-items: center;
  justify-content: center;
}

.sczTextNext {
  color: #FFF;
  text-align: center;
  /* Regular/Small */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.sczRowmarg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
  margin-left: 8px;
}
.sezColImg{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.sezImgWid{
  width: 95%;
}