.coreIncreDecreMainContainer {
    background-color: var(--ion-color-light);
    border-radius: 16px;
    padding: 16px;
    margin-top: 16px;
}

.coreQuestionText {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--ion-default-text-color);
}

.coreIncreDecre {
    padding: 16px 0 0 0;
}

.count {
    color: var(--ion-count-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding: 0 16px 0 16px
}

.coreCountTime {
    margin-left: 4px;
}