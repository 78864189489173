.coresinglecheckTitleText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-heading-text-color);
}
.coresinglecheckOptionsText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--ion-default-text-color);

  margin-right: 32px;
  margin-left: 8px;
}

.coresinglecheckCheckImg {
  width: 18px;
  height: 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 2px;
}

.coresinglecheckContainer {
  background-color: var(--ion-color-light);
  border-radius: 16px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  /* margin: 16px;  */
}
.coresingleRow{
  align-items: flex-start;
  margin-bottom: 8px;
}

.coresingleCol{
  align-items: flex-start;
}