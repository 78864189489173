.skincheck-mainContent {
  background: var(--ion-color-light);
  padding-bottom: 90px;
}

.sub-heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-color-step-200);
}

.sub-heading {
  margin: 16px 0;
}


.cceRowPalette {
  border: var(--ion-color-primary-contrast);
  border-radius: 24px;
}

.cceRowText {
  text-align: initial;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--ion-color-step-200);
  padding-top: 6px;
  /* padding-bottom: 16px; */
}

.cceColorGrid {
  background-color: var(--ion-color-primary-contrast);
  border-radius: 24px;
}

.cceColPalette {
  border-radius: 24px;
  width: 48px;
  height: 48px;
  margin-top: 16px;
  /* display: flex; */
  /* align-items: center; */
}

.cceColorCircle {
  height: 48px;
  width: 48px;
  border-radius: 24px;
}

.cceColorCircleSelected {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  border: 3px solid var(--ion-color-secondary) !important;
}

.stsColor1 {
  background-color: var(--ion-palette-color1);
}

.stsColor2 {
  background-color: var(--ion-palette-color2);
}

.stsColor3 {
  background-color: var(--ion-palette-color3);
}

.stsColor4 {
  background-color: var(--ion-palette-color4);
}

.stsColor5 {
  background-color: var(--ion-palette-color5);
}

.stsColor6 {
  background-color: var(--ion-palette-color6);
}

.stsColor7 {
  background-color: var(--ion-palette-color7);
}

.stsColor8 {
  background-color: var(--ion-palette-color8);
}

.stsColor9 {
  background-color: var(--ion-palette-color9);
}

.stsColor10 {
  background-color: var(--ion-palette-color10);
}