.mmsoImg {
  width: 57px;
}

.mmsoImgCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mmsoImg1Col {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mmsoTextDescCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.mmsoText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.mmsoDesc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-default-text-color);
}

.mmsoMainContainer {
  background-color: var(--ion-color-light);
}

.mmsoMainContainerSelected {
  background-color: var(--ion-color-light);
  border: 2px solid var(--ion-color-success)
}

.acceMainContainer.mmsoMainContainer{
  margin-top: 16px;
}
.mmsoBottomDiv {
  /* width: 100%; */
  margin-top: 16px;
  height: 29px;
  margin-left: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.mssoBottomText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-default-text-color);
}
